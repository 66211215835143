// src/components/Navbar.jsx
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Navbar = () => {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-light">
      <div className="container justify-content-center">
        {/* 
          container + justify-content-center => el contenido se centra 
        */}
        <Link to="/" className="navbar-brand me-5">
          <i className="bi bi-house-fill me-2"></i>
          Inicio
        </Link>
        
        <div className="d-flex align-items-center">
          {user ? (
            <>
              <span className="me-3">
                {user.nombres} ({user.rol})
              </span>
              <button
                className="btn btn-outline-danger btn-sm"
                onClick={handleLogout}
              >
                Cerrar Sesión
              </button>
            </>
          ) : (
            <Link to="/login" className="btn btn-primary btn-sm">
              Iniciar Sesión
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
