import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Login.css';

// Asume que tienes dos logos:
// 1) El grande: logokd.png
// 2) El pequeño: logokdSmall.png (o puedes usar el mismo con distinto tamaño)
import logoLeft from '../assets/logokd.png';
import logoRight from '../assets/logokdSmall.png';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [cedula, setCedula] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await login(cedula, password);
      navigate('/');
    } catch (err) {
      console.error(err);
      setError('Credenciales inválidas o error de servidor');
    }
  };

  return (
    <div className="container-fluid login-container">
      <div className="row login-row g-0">
        
        {/* Col Izquierda: Logo grande + degradado */}
        <div className="col-md-6 d-none d-md-flex left-col">
          {/* Logo grande */}
          <img 
            src={logoLeft} 
            alt="logo grande" 
            className="left-col-logo"
          />
          {/* Texto opcional */}
          <p className="left-col-text">
            ¡Bienvenido!<br/>
            Por favor, inicia sesión para continuar.
          </p>
        </div>

        {/* Col Derecha: Formulario estilo MS */}
        <div className="col-12 col-md-6 right-col">
          <div className="right-col-content">

            {/* Logo pequeño en la derecha */}
            <img 
              src={logoRight} 
              alt="logo small" 
              className="right-col-logo"
            />

            {/* Título estilo MS */}
            <h2 className="login-title">Iniciar Sesión</h2>

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {/* Form */}
            <form onSubmit={handleSubmit}>
              {/* Cédula */}
              <div className="mb-3">
                <label className="form-label-ms" htmlFor="cedulaInput">
                  Cédula
                </label>
                <input
                  id="cedulaInput"
                  type="text"
                  className="form-control-ms w-100"
                  value={cedula}
                  onChange={(e) => setCedula(e.target.value)}
                />
              </div>

              {/* Contraseña */}
              <div className="mb-3">
                <label className="form-label-ms" htmlFor="passwordInput">
                  Contraseña
                </label>
                <input
                  id="passwordInput"
                  type="password"
                  className="form-control-ms w-100"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {/* Botón estilo MS */}
              <button type="submit" className="btn-ms-primary">
                Ingresar
              </button>
            </form>

            <div className="ms-register-link">
              <p>
                ¿No tienes cuenta?{' '}
                <Link to="/register">
                  Regístrate aquí
                </Link>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
