// src/pages/SalidasList.jsx
import { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../api/axiosInstance';
import './SalidasList.css';

function EstadoAprobacion({ estado }) {
  // Muestra checks o x para admin y seguridad
  let adminIcon = '❌';
  let segIcon = '❌';
  if (estado === 'APROBADO_ADMIN' || estado === 'APROBADA') {
    adminIcon = '✅';
  }
  if (estado === 'APROBADA') {
    segIcon = '✅';
  }
  return (
    <p>
      <strong>Admin:</strong> {adminIcon} &nbsp; | &nbsp; 
      <strong>Seguridad:</strong> {segIcon}
    </p>
  );
}

const SalidasList = () => {
  const [salidas, setSalidas] = useState([]);
  const [detalle, setDetalle] = useState(null);
  const [error, setError] = useState('');

  // Filtros
  const [cedula, setCedula] = useState('');
  const [codigo, setCodigo] = useState('');
  const [filtroAprob, setFiltroAprob] = useState('');

  const { user } = useAuth(); // rol

  // 1. Carga inicial de salidas (última semana, según tu backend)
  useEffect(() => {
    const fetchSalidas = async () => {
      setError('');
      setDetalle(null);
      try {
        const { data } = await axiosInstance.get('/salidas');
        setSalidas(data.salidas || []);
      } catch (err) {
        console.error(err);
        setError('Error al cargar salidas');
      }
    };
    fetchSalidas();
  }, []);

  // 2. Filtrar
  const handleFiltrar = async () => {
    setError('');
    setDetalle(null);
    try {
      let url = '/salidas/filter?';
      const params = [];
      if (cedula) params.push(`cedula=${cedula}`);
      if (codigo) params.push(`codigo=${codigo}`);
      if (filtroAprob) params.push(`f=${filtroAprob}`);
      if (params.length > 0) {
        url += params.join('&');
      }
      const { data } = await axiosInstance.get(url);
      setSalidas(data.salidas || []);
    } catch (err) {
      console.error(err);
      setError('Error al filtrar salidas');
    }
  };

  // 3. Ver detalle => /salidas/:id/detalles
  const handleVerDetalle = async (idSalida) => {
    setError('');
    setDetalle(null);
    try {
      const { data } = await axiosInstance.get(`/salidas/${idSalida}/detalles`);
      setDetalle(data); // Asignamos la respuesta a 'detalle'
    } catch (err) {
      console.error(err);
      setError('Error al obtener detalle');
    }
  };

  // 4. Aprobar por seguridad
  const handleAprobarSeguridad = async (idSalida) => {
    setError('');
    try {
      await axiosInstance.put(`/salidas/approve/security/${idSalida}`);
      alert('Salida aprobada por seguridad');
      setSalidas(salidas.filter((s) => s.ID_SALIDA !== idSalida));
      setDetalle(null);
    } catch (err) {
      console.error(err);
      setError('Error al aprobar por seguridad');
    }
  };

  // Convertir varbinary => blob
  const varbinaryToBlobURL = (vbArray) => {
    if (!vbArray) return null;
    const arr = vbArray.data || vbArray; // a veces { type:'Buffer', data:[...]}
    if (!arr) return null;
    const u8 = new Uint8Array(arr);
    const blob = new Blob([u8], { type: 'image/jpeg' });
    return URL.createObjectURL(blob);
  };

  return (
    <div className="salidas-list-container">
      <h2>Listado de Salidas</h2>
      {error && <p className="error-msg">{error}</p>}

      {/* Filtros */}
      <div className="filter-bar">
        <label>Cédula:</label>
        <input
          value={cedula}
          onChange={(e) => setCedula(e.target.value)}
          placeholder="Ingresar cédula..."
        />

        <label>Código:</label>
        <input
          value={codigo}
          onChange={(e) => setCodigo(e.target.value)}
          placeholder="ID Salida"
        />

        <label>Estado/Aprobación:</label>
        <select value={filtroAprob} onChange={(e) => setFiltroAprob(e.target.value)}>
          <option value="">--Todos--</option>
          <option value="aprobadas">Aprobadas totalmente</option>
          <option value="pendientes">Pendientes (cualquier)</option>
          <option value="pend-sec">Pendientes de Seguridad</option>
          <option value="pend-admin">Pendientes de Admin</option>
        </select>
        <button onClick={handleFiltrar}>Filtrar</button>
      </div>

      {/* Tabla de resultados */}
      <table className="salidas-table" border="1" cellPadding="5">
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha</th>
            <th>Estado</th>
            <th>Creador (Cel)</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {salidas.map((s) => (
            <tr key={s.ID_SALIDA}>
              <td>{s.ID_SALIDA}</td>
              <td>{s.FECHA_SALIDA}</td>
              <td>{s.ESTADO_SALIDA}</td>
              <td>
                {s.NOMBRES_CREADOR} {s.APELLIDOS_CREADOR}<br/>
                C.I:{s.CEDULA_CREADOR}<br/>
                Cel:{s.CELULAR_CREADOR || '--'}
              </td>
              <td>
                <button onClick={() => handleVerDetalle(s.ID_SALIDA)}>
                  Ver Detalle
                </button>
                {' '}
                {/* Sólo seguridad ve el botón si ESTADO_SALIDA=APROBADO_ADMIN */}
                {user?.rol === 'seguridad' && s.ESTADO_SALIDA === 'APROBADO_ADMIN' && (
                  <button onClick={() => handleAprobarSeguridad(s.ID_SALIDA)}>
                    Aprobar Seguridad
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Detalle de la salida */}
      {detalle && (
        <div className="salidas-detail">
          <h3>Detalle de la Salida #{detalle.salida.ID_SALIDA}</h3>
          <p>Fecha/Hora: {detalle.salida.FECHA_SALIDA}</p>
          <p>
            <strong>Creador:</strong> {detalle.salida.NOMBRES_CREADOR} {detalle.salida.APELLIDOS_CREADOR}<br/>
            C.I:{detalle.salida.CEDULA_CREADOR}<br/>
            Cel:{detalle.salida.CELULAR_CREADOR || '--'}
          </p>

          {/* Documentos del creador (docsId) */}
          {detalle.salida.docsId && detalle.salida.docsId.length > 0 && (
            <div style={{ marginTop:'5px' }}>
              <strong>Documentos de Identidad (Creador):</strong>
              <div style={{ display:'flex', gap:'5px', marginTop:'5px' }}>
                {detalle.salida.docsId.map((docBin, iDoc) => {
                  const url = varbinaryToBlobURL(docBin);
                  if (!url) return null;
                  return (
                    <img
                      key={iDoc}
                      src={url}
                      alt="DocCreador"
                      style={{ width:'100px', border:'1px solid #ccc' }}
                    />
                  );
                })}
              </div>
            </div>
          )}

          <EstadoAprobacion estado={detalle.salida.ESTADO_SALIDA} />

          {/* Admin y Seguridad que aprobaron */}
          {detalle.salida.ID_ADMIN && (
            <p>
              <strong>Administrador que aprobó:</strong> {detalle.salida.NOMBRES_ADMIN} {detalle.salida.APELLIDOS_ADMIN}<br/>
              Cel: {detalle.salida.CELULAR_ADMIN || '--'}
            </p>
          )}
          {detalle.salida.ID_SEGURIDAD && (
            <p>
              <strong>Agente de seguridad:</strong> {detalle.salida.NOMBRES_SEGURIDAD} {detalle.salida.APELLIDOS_SEGURIDAD}<br/>
              Cel: {detalle.salida.CELULAR_SEGURIDAD || '--'}
            </p>
          )}

          {detalle.detalles.length === 0 ? (
            <p>No hay ítems en esta salida.</p>
          ) : (
            detalle.detalles.map((d, idx) => (
              <div key={idx} className="detalle-item">
                <h4>
                  Equipo: {d.NOMBRE_EQUIPO} ({d.MARCA_EQUIPO}-{d.MODELO_EQUIPO})
                </h4>
                <p>Serie: {d.SERIE_EQUIPO}</p>
                <p>Descripción: {d.DESCRIPCION_EQUIPO}</p>
                <p>Cantidad: {d.CANTIDAD}</p>
                <p>Destino: {d.NOMBRE_DESTINO}</p>

                {/* Responsables */}
                {d.responsables && d.responsables.length > 0 && (
                  <div>
                    <strong>Responsables:</strong>
                    <ul>
                      {d.responsables.map((r) => (
                        <li key={r.ID_USUARIO}>
                          {r.NOMBRES_USUARIO} {r.APELLIDOS_USUARIO} (C.I:{r.CEDULA_USUARIO})

                          {/* Documentos de identidad del responsable */}
                          {r.docsId && r.docsId.length > 0 && (
                            <div style={{ marginTop:'5px' }}>
                              <strong>Documentos de Identidad (Responsable):</strong>
                              <div style={{ display:'flex', gap:'5px', marginTop:'5px' }}>
                                {r.docsId.map((docBin, docIdx) => {
                                  const url = varbinaryToBlobURL(docBin);
                                  if (!url) return null;
                                  return (
                                    <img
                                      key={docIdx}
                                      src={url}
                                      alt="DocResponsable"
                                      style={{ width:'100px', border:'1px solid #ccc' }}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Fotos del item */}
                {d.fotos && d.fotos.length > 0 && (
                  <div>
                    <strong>Fotos:</strong>
                    <br/>
                    {d.fotos.map((f) => {
                      const url = varbinaryToBlobURL(f.IMAGEN_FOTO);
                      if (!url) return null;
                      return (
                        <img
                          key={f.ID_FOTO}
                          src={url}
                          alt={`FotoItem${f.ID_FOTO}`}
                          className="item-foto"
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SalidasList;
