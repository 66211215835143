import { useEffect, useState, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../api/axiosInstance';
import './SalidasList.css'; // Importa tu CSS con estilo M365

// Íconos de Material UI (opcional)
import { CheckCircle, Cancel } from '@mui/icons-material';

/**
 * Muestra íconos de aprobación (Admin / Seguridad) con un estilo sencillo.
 */
function EstadoAprobacion({ estado }) {
  let adminIcon = <Cancel className="icon-denied" />;
  let segIcon   = <Cancel className="icon-denied" />;

  // Si estado es APROBADO_ADMIN o APROBADA => check en Admin
  if (estado === 'APROBADO_ADMIN' || estado === 'APROBADA') {
    adminIcon = <CheckCircle className="icon-approved" />;
  }
  // Si está APROBADA => check en Seguridad
  if (estado === 'APROBADA') {
    segIcon = <CheckCircle className="icon-approved" />;
  }

  return (
    <p className="aprobacion-estado">
      <strong>Admin:</strong> {adminIcon} &nbsp;|&nbsp;
      <strong>Seguridad:</strong> {segIcon}
    </p>
  );
}

const SalidasList = () => {
  const [salidas, setSalidas] = useState([]);
  const [detalle, setDetalle] = useState(null);
  const [error, setError] = useState('');

  // Filtros
  const [cedula, setCedula] = useState('');
  const [codigo, setCodigo] = useState('');
  // Por defecto, 'pend-sec' para ver solo pendientes de seguridad
  const [filtroAprob, setFiltroAprob] = useState('pend-sec');
  const [dia, setDia] = useState('');
  const [mes, setMes] = useState('');
  const [anio, setAnio] = useState('');

  const { user } = useAuth();

  // Referencias a modals
  const detailModalRef = useRef(null);
  const photoModalRef  = useRef(null);
  const [modalImage, setModalImage] = useState(null);

  // Al montar, inicializamos los modals y llamamos a handleFiltrar()
  useEffect(() => {
    detailModalRef.current = new window.bootstrap.Modal(document.getElementById('detailModal'));
    photoModalRef.current  = new window.bootstrap.Modal(document.getElementById('photoModal'));
    
    // Filtra de inmediato, en vez de cargar todas las salidas
    handleFiltrar();
  }, []);

  /**
   * Filtra las salidas según los estados (filtroAprob), cedula, codigo, etc.
   */
  const handleFiltrar = async () => {
    setError('');
    setDetalle(null);
    try {
      let url = '/salidas/filter?';
      const params = [];
      if (cedula) params.push(`cedula=${cedula}`);
      if (codigo) params.push(`codigo=${codigo}`);
      if (filtroAprob) params.push(`f=${filtroAprob}`);
      if (dia) params.push(`dia=${dia}`);
      if (mes) params.push(`mes=${mes}`);
      if (anio) params.push(`anio=${anio}`);

      if (params.length > 0) {
        url += params.join('&');
      }

      const { data } = await axiosInstance.get(url);
      setSalidas(data.salidas || []);
    } catch (err) {
      console.error(err);
      setError('Error al filtrar salidas');
    }
  };

  /**
   * Ver el detalle de la salida (en modal)
   */
  const handleVerDetalle = async (idSalida) => {
    // Si ya tenemos detalle de esa misma salida abierto, lo cerramos
    if (detalle && detalle.salida.ID_SALIDA === idSalida) {
      closeDetailModal();
      return;
    }
    try {
      setError('');
      setDetalle(null);
      const { data } = await axiosInstance.get(`/salidas/${idSalida}/detalles`);
      setDetalle(data);
      detailModalRef.current.show();
    } catch (err) {
      console.error(err);
      setError('Error al obtener detalle');
    }
  };

  /**
   * Cerrar el modal de detalle
   */
  const closeDetailModal = () => {
    detailModalRef.current.hide();
    setDetalle(null);
  };

  // Al cerrarse el modal de detalle (por fuera), limpiar el detalle
  useEffect(() => {
    const modalEl = document.getElementById('detailModal');
    modalEl.addEventListener('hidden.bs.modal', () => {
      setDetalle(null);
    });
  }, []);

  /**
   * Aprobar por Seguridad
   */
  const handleAprobarSeguridad = async (idSalida) => {
    setError('');
    try {
      detailModalRef.current.hide();
      setDetalle(null);
      await axiosInstance.put(`/salidas/approve/security/${idSalida}`);
      alert('Salida aprobada por seguridad');
      // Quitar de la lista las salidas ya aprobadas o recargar
      setSalidas((prev) => prev.filter((s) => s.ID_SALIDA !== idSalida));
    } catch (err) {
      console.error(err);
      setError('Error al aprobar por seguridad');
    }
  };

  /**
   * Mostrar foto en modal fullscreen
   */
  const handlePreviewPhoto = (url) => {
    setModalImage(url);
    photoModalRef.current.show();
  };

  const closePhotoModal = () => {
    photoModalRef.current.hide();
    setModalImage(null);
  };

  /**
   * Helper para convertir varbinary => Blob URL
   */
  const varbinaryToBlobURL = (vbArray) => {
    if (!vbArray) return null;
    const arr = vbArray.data || vbArray;
    if (!arr) return null;
    const u8 = new Uint8Array(arr);
    const blob = new Blob([u8], { type: 'image/jpeg' });
    return URL.createObjectURL(blob);
  };

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4 ms365-title">Listado de Salidas</h2>
      {error && <div className="alert alert-danger">{error}</div>}

      {/* Filtros */}
      <div className="mb-3 card ms365-filter-card p-3">
        <div className="row g-3 align-items-center">
          <div className="col-auto">
            <label className="col-form-label fw-bold">Cédula:</label>
          </div>
          <div className="col-auto">
            <input
              type="text"
              className="form-control"
              placeholder="Cédula..."
              style={{ width: '120px' }}
              value={cedula}
              onChange={(e) => setCedula(e.target.value)}
            />
          </div>

          <div className="col-auto">
            <label className="col-form-label fw-bold">Código:</label>
          </div>
          <div className="col-auto">
            <input
              type="text"
              className="form-control"
              placeholder="ID Salida..."
              style={{ width: '100px' }}
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            />
          </div>

          <div className="col-auto">
            <label className="col-form-label fw-bold">Estado:</label>
          </div>
          <div className="col-auto">
            <select
              className="form-select"
              value={filtroAprob}
              onChange={(e) => setFiltroAprob(e.target.value)}
            >
              <option value="">--Todos--</option>
              <option value="aprobadas">Aprobadas</option>
              <option value="pendientes">Pendientes (cualquier)</option>
              <option value="pend-sec">Pend. Seguridad</option>
              <option value="pend-admin">Pend. Admin</option>
            </select>
          </div>

          <div className="col-auto">
            <label className="col-form-label fw-bold">Día:</label>
          </div>
          <div className="col-auto">
            <input
              type="number"
              min="1"
              max="31"
              className="form-control"
              style={{ width: '70px' }}
              value={dia}
              onChange={(e) => setDia(e.target.value)}
            />
          </div>

          <div className="col-auto">
            <label className="col-form-label fw-bold">Mes:</label>
          </div>
          <div className="col-auto">
            <input
              type="number"
              min="1"
              max="12"
              className="form-control"
              style={{ width: '70px' }}
              value={mes}
              onChange={(e) => setMes(e.target.value)}
            />
          </div>

          <div className="col-auto">
            <label className="col-form-label fw-bold">Año:</label>
          </div>
          <div className="col-auto">
            <input
              type="number"
              min="2020"
              max="2100"
              className="form-control"
              style={{ width: '80px' }}
              value={anio}
              onChange={(e) => setAnio(e.target.value)}
            />
          </div>

          <div className="col-auto">
            <button className="btn btn-ms365" onClick={handleFiltrar}>
              Filtrar
            </button>
          </div>
        </div>
      </div>

      {/* Grid de tarjetas */}
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 mt-4">
        {salidas.map((s) => (
          <div className="col" key={s.ID_SALIDA}>
            <div className="card ms365-card h-100">
              <div className="card-body d-flex flex-column justify-content-between">
                {/* Contenido principal */}
                <div>
                  <h5 className="card-title ms365-card-title">
                    Salida #{s.ID_SALIDA}
                  </h5>
                  <p className="card-text mb-1">
                    <strong>Fecha:</strong> {s.FECHA_SALIDA}
                  </p>
                  <p className="card-text mb-1">
                    <strong>Estado:</strong> {s.ESTADO_SALIDA}
                  </p>
                  <p className="card-text">
                    <strong>Creador:</strong> {s.NOMBRES_CREADOR} {s.APELLIDOS_CREADOR}
                    <br />
                    <small>
                      C.I: {s.CEDULA_CREADOR} | Cel: {s.CELULAR_CREADOR || '--'}
                    </small>
                  </p>
                </div>

                {/* Línea divisoria (abajo) */}
                <hr className="ms365-divider" />

                {/* Botones de acción */}
                <div className="mt-3 d-flex gap-2">
                  <button
                    className="btn btn-ms365 btn-sm"
                    onClick={() => handleVerDetalle(s.ID_SALIDA)}
                  >
                    Ver Detalle
                  </button>
                  {/* Mostrar botón "Aprobar Seguridad" si user=seguridad y estado=APROBADO_ADMIN */}
                  {user?.rol === 'seguridad' && s.ESTADO_SALIDA === 'APROBADO_ADMIN' && (
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleAprobarSeguridad(s.ID_SALIDA)}
                    >
                      Aprobar Seguridad
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal de detalle */}
      <div className="modal fade" id="detailModal">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div className="modal-content">
            {detalle && (
              <>
                <div className="modal-header">
                  <h5 className="modal-title">
                    Detalle de la Salida #{detalle.salida.ID_SALIDA}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body overflow-auto">
                  <p>
                    <strong>Fecha/Hora:</strong> {detalle.salida.FECHA_SALIDA}
                  </p>
                  <p>
                    <strong>Creador:</strong> {detalle.salida.NOMBRES_CREADOR}{' '}
                    {detalle.salida.APELLIDOS_CREADOR}
                    <br />
                    <small>
                      C.I: {detalle.salida.CEDULA_CREADOR} | Cel:{' '}
                      {detalle.salida.CELULAR_CREADOR || '--'}
                    </small>
                  </p>

                  {/* Documentos del creador */}
                  {detalle.salida.docsId && detalle.salida.docsId.length > 0 && (
                    <div className="mb-3">
                      <strong>Documentos (Creador):</strong>
                      <div className="d-flex gap-2 flex-wrap mt-2">
                        {detalle.salida.docsId.map((docBin, iDoc) => {
                          const url = varbinaryToBlobURL(docBin);
                          if (!url) return null;
                          return (
                            <img
                              key={iDoc}
                              src={url}
                              alt="DocCreador"
                              className="img-thumbnail"
                              style={{ width: '100px', cursor: 'pointer' }}
                              onClick={() => handlePreviewPhoto(url)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <EstadoAprobacion estado={detalle.salida.ESTADO_SALIDA} />

                  {detalle.salida.ID_ADMIN && (
                    <p>
                      <strong>Administrador que aprobó:</strong>{' '}
                      {detalle.salida.NOMBRES_ADMIN} {detalle.salida.APELLIDOS_ADMIN}
                      <br />
                      <small>Cel: {detalle.salida.CELULAR_ADMIN || '--'}</small>
                    </p>
                  )}
                  {detalle.salida.ID_SEGURIDAD && (
                    <p>
                      <strong>Agente de seguridad:</strong>{' '}
                      {detalle.salida.NOMBRES_SEGURIDAD}{' '}
                      {detalle.salida.APELLIDOS_SEGURIDAD}
                      <br />
                      <small>Cel: {detalle.salida.CELULAR_SEGURIDAD || '--'}</small>
                    </p>
                  )}

                  {/* Items */}
                  {detalle.detalles.length === 0 ? (
                    <p>No hay ítems en esta salida.</p>
                  ) : (
                    detalle.detalles.map((d, idx) => (
                      <div key={idx} className="border p-2 mb-3">
                        <h5>
                          Equipo: {d.NOMBRE_EQUIPO} ({d.MARCA_EQUIPO}-{d.MODELO_EQUIPO})
                        </h5>
                        <p><strong>Serie:</strong> {d.SERIE_EQUIPO}</p>
                        <p><strong>Descripción:</strong> {d.DESCRIPCION_EQUIPO}</p>
                        <p><strong>Cantidad:</strong> {d.CANTIDAD}</p>
                        <p><strong>Destino:</strong> {d.NOMBRE_DESTINO}</p>

                        {/* Responsables */}
                        {d.responsables && d.responsables.length > 0 && (
                          <div>
                            <strong>Responsables:</strong>
                            <ul className="mb-2">
                              {d.responsables.map((r) => (
                                <li key={r.ID_USUARIO}>
                                  {r.NOMBRES_USUARIO} {r.APELLIDOS_USUARIO} (C.I: {r.CEDULA_USUARIO})
                                  {/* Documentos ID del responsable */}
                                  {r.docsId && r.docsId.length > 0 && (
                                    <div className="mt-1 d-flex gap-2 flex-wrap">
                                      <strong>Docs:</strong>
                                      {r.docsId.map((docBin, docIdx) => {
                                        const url = varbinaryToBlobURL(docBin);
                                        if (!url) return null;
                                        return (
                                          <img
                                            key={docIdx}
                                            src={url}
                                            alt="DocResp"
                                            className="img-thumbnail"
                                            style={{ width: '80px', cursor: 'pointer' }}
                                            onClick={() => handlePreviewPhoto(url)}
                                          />
                                        );
                                      })}
                                    </div>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {/* Fotos del ítem */}
                        {d.fotos && d.fotos.length > 0 && (
                          <div>
                            <strong>Fotos del Ítem:</strong><br />
                            <div className="d-flex gap-2 flex-wrap mt-1">
                              {d.fotos.map((f) => {
                                const url = varbinaryToBlobURL(f.IMAGEN_FOTO);
                                if (!url) return null;
                                return (
                                  <img
                                    key={f.ID_FOTO}
                                    src={url}
                                    alt={`FotoItem${f.ID_FOTO}`}
                                    className="img-thumbnail"
                                    style={{ width: '80px', cursor: 'pointer' }}
                                    onClick={() => handlePreviewPhoto(url)}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  )}
                </div>
                <div className="modal-footer">
                  {user?.rol === 'seguridad' &&
                    detalle.salida.ESTADO_SALIDA === 'APROBADO_ADMIN' && (
                      <button
                        className="btn btn-success"
                        onClick={() => handleAprobarSeguridad(detalle.salida.ID_SALIDA)}
                      >
                        Aprobar Seguridad
                      </button>
                    )}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={closeDetailModal}
                  >
                    Cerrar
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Modal foto fullscreen */}
      <div className="modal fade" id="photoModal" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Vista Previa</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closePhotoModal}
              ></button>
            </div>
            <div className="modal-body d-flex justify-content-center align-items-center">
              {modalImage && (
                <img
                  src={modalImage}
                  alt="FotoFullscreen"
                  style={{ maxWidth: '100%', maxHeight: '90vh' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalidasList;
