import { useState, useEffect, useRef } from 'react';
import axiosInstance from '../api/axiosInstance';
import './SalidaCreate.css';

const SalidaCreate = () => {
  // ---------- ESTADOS GENERALES ----------
  const [motivo, setMotivo] = useState('');
  const [items, setItems] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [msg, setMsg] = useState('');
  const [error, setError] = useState('');
  const [modalImage, setModalImage] = useState(null);

  // Filtro de responsables
  const [filtroResponsable, setFiltroResponsable] = useState('');
  // Mostrar/ocultar checks de responsables
  const [mostrarResponsables, setMostrarResponsables] = useState(true);

  // Refs para scroll a la cabecera de cada ítem
  const itemHeaderRefs = useRef([]);

  // ---------- INICIALIZAR TOOLTIP (Bootstrap) ----------
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.forEach((el) => new window.bootstrap.Tooltip(el));
  }, []);

  // ---------- CARGAR USUARIOS y DESTINOS ----------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [uRes, dRes] = await Promise.all([
          axiosInstance.get('/users/s?estado=ACTIVO'),
          axiosInstance.get('/destinos'),
        ]);
        setUsuarios(uRes.data || []);
        setDestinos(dRes.data || []);
      } catch (err) {
        console.error(err);
        setError('Error al cargar usuarios/destinos');
      }
    };
    fetchData();
  }, []);

  // ---------- DEBUG: Observa cambios en items (opcional) ----------
  useEffect(() => {
    console.log('items changed:', items);
  }, [items]);

  // ---------- AGREGAR ÍTEM ----------
  const handleAddItem = () => {
    setItems((prev) => {
      const newItems = [
        ...prev,
        {
          equipo: { nombre: '', marca: '', modelo: '', serie: '', descripcion: '' },
          destinoId: 0,
          destinoName: '',
          cantidad: 1,
          responsables: [],
          files: [],
          selected: false,
          uniqueId: `u${Date.now()}-${prev.length + 1}`,
        },
      ];
      return newItems;
    });

    setTimeout(() => {
      const lastIndex = items.length;
      const ref = itemHeaderRefs.current[lastIndex];
      if (ref) {
        ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  // ---------- DUPLICAR ÍTEM SELECCIONADO ----------
  const handleDuplicateSelected = () => {
    setError('');
    const selectedIndex = items.findIndex((it) => it.selected);
    if (selectedIndex === -1) {
      setError('No hay un ítem seleccionado para duplicar');
      return;
    }
    const orig = items[selectedIndex];
    const clone = {
      equipo: {
        nombre: orig.equipo.nombre,
        marca: orig.equipo.marca,
        modelo: orig.equipo.modelo,
        serie: '', // al duplicar, limpiamos la serie
        descripcion: orig.equipo.descripcion,
      },
      destinoId: 0,
      destinoName: '',
      cantidad: 1,
      responsables: [...orig.responsables],
      files: [],
      selected: false,
      uniqueId: `u${Date.now()}-dup`,
    };
    setItems((prev) => [...prev, clone]);

    setTimeout(() => {
      const newIndex = items.length;
      const ref = itemHeaderRefs.current[newIndex];
      if (ref) {
        ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  // ---------- ELIMINAR ÍTEM ----------
  const handleRemoveItem = (idx) => {
    setItems((prev) => {
      const updated = [...prev];
      updated.splice(idx, 1);
      return updated;
    });
  };

  // ---------- SELECCIONAR (solo 1) ----------
  const handleSelectItem = (idx) => {
    setItems((prev) =>
      prev.map((it, i) => ({ ...it, selected: i === idx }))
    );
  };

  // ---------- CAMPOS DEL ÍTEM ----------
  const handleEquipoChange = (idx, field, value) => {
    setItems((prev) => {
      const updated = [...prev];
      // Forzamos cantidad=1 si hay serie
      if (field === 'serie') {
        updated[idx].equipo.serie = value;
        if (value.trim() !== '') {
          updated[idx].cantidad = 1;
        }
      } else {
        updated[idx].equipo[field] = value;
      }
      return updated;
    });
  };

  const handleDestinoNameChange = (idx, value) => {
    setItems((prev) => {
      const updated = [...prev];
      updated[idx].destinoName = value;
      updated[idx].destinoId = 0;
      return updated;
    });
  };

  const handleCantidadChange = (idx, val) => {
    const numVal = parseInt(val, 10) || 1;
    setItems((prev) => {
      const updated = [...prev];
      updated[idx].cantidad = numVal;
      return updated;
    });
  };

  // ---------- RESPONSABLES ----------
  const handleResponsableToggle = (idx, userId) => {
    const updated = [...items];
    const arr = updated[idx].responsables;
    if (arr.includes(userId)) {
      updated[idx].responsables = arr.filter((r) => r !== userId);
    } else {
      updated[idx].responsables = [...arr, userId];
    }
    setItems(updated);
  };

  // Filtrado local
  const usuariosFiltrados = usuarios.filter((u) => {
    const fullName = (u.NOMBRES_USUARIO + ' ' + u.APELLIDOS_USUARIO).toLowerCase();
    return (
      fullName.includes(filtroResponsable.toLowerCase()) ||
      u.CEDULA_USUARIO.includes(filtroResponsable)
    );
  });

  // Búsqueda remota
  const handleRemoteSearch = async () => {
    setError('');
    try {
      if (!filtroResponsable.trim()) {
        const { data } = await axiosInstance.get('/users/s?estado=ACTIVO');
        setUsuarios(data || []);
      } else {
        const isAllDigits = /^\d+$/.test(filtroResponsable);
        const params = isAllDigits
          ? { cedula: filtroResponsable }
          : { nombre: filtroResponsable };
        const { data } = await axiosInstance.get('/users/s', { params });
        setUsuarios(data || []);
      }
    } catch (err) {
      console.error(err);
      setError('Error al filtrar usuarios en el servidor');
    }
  };

  // ---------- FOTOS ----------
  const handleFileChange = (idx, e) => {
    const fileArray = Array.from(e.target.files);
    setItems((prev) => {
      const updated = [...prev];
      const existingSignatures = updated[idx].files.map(
        (f) => f.name + '_' + f.size
      );
      const newUniqueFiles = fileArray.filter(
        (f) => !existingSignatures.includes(f.name + '_' + f.size)
      );
      updated[idx].files = [...updated[idx].files, ...newUniqueFiles];
      return updated;
    });
    e.target.value = null;
  };

  const handleRemovePhoto = (idx, fileIndex) => {
    setItems((prev) => {
      const updated = [...prev];
      updated[idx].files.splice(fileIndex, 1);
      return updated;
    });
  };

  const handlePreviewImage = (file) => {
    const url = URL.createObjectURL(file);
    setModalImage(url);
    const modal = new window.bootstrap.Modal(document.getElementById('imageModal'));
    modal.show();
  };

  // ---------- DESTINO: SUGERENCIAS ----------
  const getDestinoSuggestions = (text) => {
    if (!text.trim()) return [];
    const lower = text.toLowerCase();
    return destinos.filter((d) =>
      d.NOMBRE_DESTINO.toLowerCase().includes(lower)
    );
  };

  const handlePickDestino = (idx, d) => {
    setItems((prev) => {
      const updated = [...prev];
      updated[idx].destinoId = d.ID_DESTINO;
      updated[idx].destinoName = d.NOMBRE_DESTINO;
      return updated;
    });
  };

  // ---------- GUARDAR ----------
  const handleSubmit = async () => {
    setError('');
    setMsg('');

    // Validaciones mínimas
    if (!motivo.trim()) {
      setError('El motivo es obligatorio');
      return;
    }
    if (items.length === 0) {
      setError('Agrega al menos un ítem');
      return;
    }
    for (let i = 0; i < items.length; i++) {
      const it = items[i];
      if (
        !it.equipo.nombre.trim() ||
        !it.equipo.marca.trim() ||
        !it.equipo.modelo.trim() ||
        !it.equipo.descripcion.trim()
      ) {
        setError(`Faltan campos en ítem #${i + 1}`);
        return;
      }
      if (!it.destinoId && !it.destinoName.trim()) {
        setError(`El destino es obligatorio en ítem #${i + 1}`);
        return;
      }
    }

    const salidaInfo = { motivo, items: [] };
    const allFiles = [];
    let fileCounter = 0;

    async function crearDestinoAlVuelo(nombreDest) {
      const resp = await axiosInstance.post('/destinos', {
        nombre: nombreDest.trim(),
      });
      return resp.data.id; // Ajusta según tu backend
    }

    for (let i = 0; i < items.length; i++) {
      const it = items[i];
      let finalDestinoId = it.destinoId;
      if (!finalDestinoId && it.destinoName.trim()) {
        try {
          finalDestinoId = await crearDestinoAlVuelo(it.destinoName);
        } catch (err) {
          console.error(err);
          setError(`Error al crear destino: ${it.destinoName}`);
          return;
        }
      }

      const fotoIndex = it.files.map((_, index) => fileCounter + index);
      fileCounter += it.files.length;

      salidaInfo.items.push({
        equipo: { ...it.equipo },
        idDestino: finalDestinoId,
        cantidad: it.cantidad || 1,
        responsables: it.responsables.map(Number),
        fotoIndex,
      });
      allFiles.push(...it.files);
    }

    const formData = new FormData();
    formData.append('salidaInfo', JSON.stringify(salidaInfo));
    allFiles.forEach((f) => {
      formData.append('fotos', f);
    });

    try {
      const { data } = await axiosInstance.post('/salidas', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMsg(`Salida creada con éxito. ID: ${data.idSalida}`);
      // Limpieza de estados
      setMotivo('');
      setItems([]);
    } catch (err) {
      console.error(err);
      setError('Error al crear la salida');
    }
  };

  return (
    <div className="sc-container-m365">
      {/* SIDEBAR M365 (fijo a la izquierda en horizontal/ancho, arriba en vertical/estrecho) */}
      <div className="sc-sidebar-m365 d-flex flex-column align-items-center">
        <button
          type="button"
          className="m365-btn m365-btn-primary mb-2"
          onClick={handleAddItem}
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Agregar Ítem"
        >
          <i className="bi bi-plus-square fs-4"></i>
        </button>

        <button
          type="button"
          className="m365-btn m365-btn-info mb-2"
          onClick={handleDuplicateSelected}
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Duplicar Ítem"
        >
          <i className="bi bi-files fs-4"></i>
        </button>

        <button
          type="button"
          className="m365-btn m365-btn-success"
          onClick={handleSubmit}
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Guardar Salida"
        >
          <i className="bi bi-save fs-4"></i>
        </button>
      </div>

      {/* CONTENIDO PRINCIPAL */}
      <div className="sc-content-m365">
        {error && <div className="alert alert-danger">{error}</div>}
        {msg && <div className="alert alert-success">{msg}</div>}

        {/* Motivo de la salida */}
        <div className="mb-3">
          <label className="form-label fw-bold">Motivo de la Salida:</label>
          <textarea
            className="form-control"
            rows={2}
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
          />
        </div>

        {/* LISTA DE ÍTEMS */}
        {items.map((it, idx) => (
          <div
            key={it.uniqueId}
            className="card mb-4 shadow m365-item-card"
          >
            <div
              className="card-header d-flex justify-content-between align-items-center"
              ref={(el) => (itemHeaderRefs.current[idx] = el)}
            >
              <div>
                <input
                  type="checkbox"
                  className="form-check-input me-2"
                  checked={it.selected}
                  onChange={() => handleSelectItem(idx)}
                />
                <strong>Ítem #{idx + 1}</strong>
              </div>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => handleRemoveItem(idx)}
              >
                X
              </button>
            </div>

            <div className="card-body">
              <div className="row g-3">
                {/* Nombre / Marca / Modelo */}
                <div className="col-md-4">
                  <label className="form-label fw-bold">Equipo/Objeto:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={it.equipo.nombre}
                    onChange={(e) => handleEquipoChange(idx, 'nombre', e.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label fw-bold">Marca:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={it.equipo.marca}
                    onChange={(e) => handleEquipoChange(idx, 'marca', e.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label fw-bold">Modelo:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={it.equipo.modelo}
                    onChange={(e) => handleEquipoChange(idx, 'modelo', e.target.value)}
                  />
                </div>

                {/* Serie / Descripción */}
                <div className="col-md-6">
                  <label className="form-label fw-bold">Serie:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="N/A o texto extenso"
                    value={it.equipo.serie}
                    onChange={(e) => handleEquipoChange(idx, 'serie', e.target.value)}
                  />
                  {it.equipo.serie.trim() !== '' && (
                    <small className="text-info">
                      Cantidad forzada a 1 al tener serie
                    </small>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="form-label fw-bold">Descripción:</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    value={it.equipo.descripcion}
                    onChange={(e) => handleEquipoChange(idx, 'descripcion', e.target.value)}
                  />
                </div>

                {/* Destino y Cantidad */}
                <div className="col-md-6">
                  <label className="form-label fw-bold">Destino:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese o seleccione un destino..."
                    value={it.destinoName}
                    onChange={(e) => handleDestinoNameChange(idx, e.target.value)}
                  />
                  {it.destinoName.trim() && (
                    <div className="border p-2 mt-1 m365-destino-suggestions">
                      {getDestinoSuggestions(it.destinoName).length === 0 ? (
                        <small className="text-muted">
                          Sin coincidencias (se creará nuevo)
                        </small>
                      ) : (
                        getDestinoSuggestions(it.destinoName).map((d) => (
                          <div
                            key={d.ID_DESTINO}
                            className="m365-suggestion-item"
                            onClick={() => handlePickDestino(idx, d)}
                          >
                            {d.NOMBRE_DESTINO}
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="form-label fw-bold">Cantidad:</label>
                  <input
                    type="number"
                    min="1"
                    className="form-control"
                    /* Deshabilita cantidad solo si hay serie */
                    disabled={it.equipo.serie.trim() !== ''}
                    value={it.cantidad}
                    onChange={(e) => handleCantidadChange(idx, e.target.value)}
                  />
                </div>
              </div>

              {/* RESPONSABLES */}
              <div className="mt-3">
                <label className="form-label fw-bold">Responsables:</label>
                {mostrarResponsables && (
                  <div className="form-control" style={{ minHeight: '60px' }}>
                    {usuariosFiltrados.map((u) => {
                      const userIdStr = String(u.ID_USUARIO);
                      const checked = it.responsables.includes(userIdStr);
                      return (
                        <div key={u.ID_USUARIO}>
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={checked}
                            onChange={() => handleResponsableToggle(idx, userIdStr)}
                          />
                          <label className="form-check-label">
                            {u.NOMBRES_USUARIO} {u.APELLIDOS_USUARIO} (CI:{u.CEDULA_USUARIO})
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="mt-2 d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex align-items-center gap-1">
                    <label className="fw-bold">Filtrar:</label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ maxWidth: '120px' }}
                      placeholder="Búsqueda..."
                      value={filtroResponsable}
                      onChange={(e) => setFiltroResponsable(e.target.value)}
                    />
                  </div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={handleRemoteSearch}
                  >
                    Buscar
                  </button>
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={() => setMostrarResponsables(!mostrarResponsables)}
                  >
                    {mostrarResponsables ? 'Ocultar' : 'Mostrar'}
                  </button>
                </div>
              </div>

              {/* FOTOS */}
              <div className="mt-3">
                <label className="form-label fw-bold">Fotos (múltiples):</label>
                <input
                  type="file"
                  className="form-control"
                  multiple
                  onChange={(e) => handleFileChange(idx, e)}
                />
                {it.files.length > 0 && (
                  <div className="mt-2 d-flex flex-wrap gap-2">
                    {it.files.map((file, fileIndex) => {
                      const previewUrl = URL.createObjectURL(file);
                      return (
                        <div
                          key={fileIndex}
                          className="position-relative"
                          style={{ width: '100px', height: '100px' }}
                        >
                          <img
                            src={previewUrl}
                            alt="preview"
                            className="img-thumbnail"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              cursor: 'pointer',
                            }}
                            onClick={() => handlePreviewImage(file)}
                          />
                          <button
                            type="button"
                            className="btn btn-sm btn-danger position-absolute top-0 end-0"
                            onClick={() => handleRemovePhoto(idx, fileIndex)}
                          >
                            X
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            {/* Línea divisoria (al final de la tarjeta) */}
            <hr className="ms365-divider" />
          </div>
        ))}
      </div>

      {/* MODAL => fullscreen foto */}
      <div
        className="modal fade"
        id="imageModal"
        tabIndex="-1"
        aria-labelledby="imageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="imageModalLabel">
                Vista Previa
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex justify-content-center align-items-center">
              {modalImage && (
                <img
                  src={modalImage}
                  alt="preview-larger"
                  style={{ maxWidth: '100%', maxHeight: '90vh' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalidaCreate;
