// src/pages/DashboardEncargado.jsx
import { Link } from 'react-router-dom';

const DashboardEncargado = () => {
  return (
    <div>
      <h2>Panel de Encargado</h2>
      <nav>
        <ul>
          <li><Link to="/salidas/crear">Crear Salida</Link></li>
          {/* Nuevo: Mis Salidas */}
          <li><Link to="/mis-salidas">Mis Salidas/colaboraciones</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default DashboardEncargado;
