import { Link } from 'react-router-dom';
import './DashboardAdmin.css';

function DashboardAdmin() {
  return (
    <div className="dashboard-admin-container mt-4">
      <h2 className="text-center mb-4 dashboard-admin-title">Panel de Administrador</h2>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
        
        {/* Card 1: Ver todas las Salidas */}
        <div className="col">
          <div className="card card-microsoft h-100 text-center">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-list-ul icon-microsoft mb-3"></i>
              <h5 className="card-title">Ver todas las Salidas</h5>
              <hr className="ms365-divider" />
            </div>
            <Link to="/salidas" className="stretched-link"></Link>
          </div>
        </div>

        {/* Card 2: Crear Nueva Salida */}
        <div className="col">
          <div className="card card-microsoft h-100 text-center">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-plus-square icon-microsoft mb-3"></i>
              <h5 className="card-title">Crear Nueva Salida</h5>
              <hr className="ms365-divider" />
            </div>
            <Link to="/salidas/crear" className="stretched-link"></Link>
          </div>
        </div>

        {/* Card 3: Aprobar Salidas Pendientes */}
        <div className="col">
          <div className="card card-microsoft h-100 text-center">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-check2-circle icon-microsoft mb-3"></i>
              <h5 className="card-title">Aprobar Salidas Pendientes</h5>
              <hr className="ms365-divider" />
            </div>
            <Link to="/admin/aprobar" className="stretched-link"></Link>
          </div>
        </div>

        {/* Card 4: Gestión de Usuarios */}
        <div className="col">
          <div className="card card-microsoft h-100 text-center">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-people icon-microsoft mb-3"></i>
              <h5 className="card-title">Gestión de Usuarios</h5>
              <hr className="ms365-divider" />
            </div>
            <Link to="/admin/users" className="stretched-link"></Link>
          </div>
        </div>

        {/* Card 5: Mis Salidas/colaboraciones */}
        <div className="col">
          <div className="card card-microsoft h-100 text-center">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-clock-history icon-microsoft mb-3"></i>
              <h5 className="card-title">Mis Salidas/colaboraciones</h5>
              <hr className="ms365-divider" />
            </div>
            <Link to="/mis-salidas" className="stretched-link"></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAdmin;
