// src/context/AuthContext.jsx
import { createContext, useState, useContext } from 'react';
import axiosInstance from '../api/axiosInstance';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Registrar usuario => manda {cedula, nombres, apellidos, celular, password}
  const registerUser = async (datosUser, docsFiles) => {
    // datosUser => {cedula, nombres, apellidos, celular, password}
    // docsFiles => array de File
    const formData = new FormData();
    formData.append('registerInfo', JSON.stringify(datosUser));
    if (docsFiles && docsFiles.length>0) {
      for(const file of docsFiles) {
        formData.append('docs', file);
      }
    }

    const { data } = await axiosInstance.post('/auth/register', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return data; // { message: 'Usuario registrado...' }
  };

  // Login => guarda token en localStorage y setea user
  const login = async (cedula, password) => {
    const { data } = await axiosInstance.post('/auth/login', { cedula, password });
    // data => { message, token, user: {...} }
    localStorage.setItem('token', data.token);
    setUser(data.user);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, setUser, registerUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
