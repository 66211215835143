import { Link } from 'react-router-dom';
import './DashboardSeguridad.css'; // Importa tu CSS con estilo M365

function DashboardSeguridad() {
  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4 ms365-title">Panel de Seguridad</h2>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
        {/* Card 1: Ver Salidas Aprobadas por Admin */}
        <div className="col">
          <div className="card card-microsoft h-100 text-center">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              {/* Ícono de ejemplo (Bootstrap Icons) */}
              <i className="bi bi-eye icon-microsoft mb-3"></i>
              <h5 className="card-title">Ver Salidas Aprobadas por Admin</h5>
              {/* Línea divisoria estilo MS365 */}
              <hr className="ms365-divider" />
            </div>
            {/* Link que hace clickeable toda la tarjeta */}
            <Link to="/salidas" className="stretched-link"></Link>
          </div>
        </div>

        {/* Card 2: Mis Salidas/colaboraciones */}
        <div className="col">
          <div className="card card-microsoft h-100 text-center">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-clock-history icon-microsoft mb-3"></i>
              <h5 className="card-title">Mis Salidas/colaboraciones</h5>
              <hr className="ms365-divider" />
            </div>
            <Link to="/mis-salidas" className="stretched-link"></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardSeguridad;
