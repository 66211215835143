import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Register.css'; // Importa el CSS con el gradiente y estilo MS
import logoRight from '../assets/logokd.png';

const Register = () => {
  const navigate = useNavigate();
  const { registerUser } = useAuth();

  const [cedula, setCedula] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [celular, setCelular] = useState('');
  const [password, setPassword] = useState('');

  const [docs, setDocs] = useState([]); // Archivos
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');

  const handleFileChange = (e) => {
    setDocs(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMsg('');

    if (!cedula || !nombres || !apellidos || !password) {
      setError('Por favor llena todos los campos obligatorios');
      return;
    }

    try {
      await registerUser({ cedula, nombres, apellidos, celular, password }, docs);
      setMsg('Usuario registrado con éxito (rol = encargado)');
      setTimeout(() => {
        navigate('/salidaskfc.login');
      }, 2000);
    } catch (err) {
      console.error(err);
      setError('Error al registrar usuario: ' + (err.response?.data?.message || ''));
    }
  };

  return (
    <div className="register-bg">
      <div className="register-card">
        {/* Logo pequeño en la derecha */}
                    <img 
                      src={logoRight} 
                      alt="logo small" 
                      className="left-col-logo"
                      style={{ maxWidth: '140px', height: 'auto' }}
                    />
        <h2 className="register-title">
          Crear cuenta
        </h2>

        {error && <div className="alert alert-danger">{error}</div>}
        {msg && <div className="alert alert-success">{msg}</div>}

        <form onSubmit={handleSubmit}>
          {/* Fila de campos */}
          <div className="row-g-3">
            {/* Cédula */}
            <div className="col-md-6-ms">
              <label className="form-label-ms" htmlFor="cedulaInput">Cédula:</label>
              <input
                id="cedulaInput"
                type="text"
                className="form-control-ms"
                value={cedula}
                onChange={(e) => setCedula(e.target.value)}
              />
            </div>

            {/* Nombres */}
            <div className="col-md-6-ms">
              <label className="form-label-ms" htmlFor="nombresInput">Nombres:</label>
              <input
                id="nombresInput"
                type="text"
                className="form-control-ms"
                value={nombres}
                onChange={(e) => setNombres(e.target.value)}
              />
            </div>

            {/* Apellidos */}
            <div className="col-md-6-ms">
              <label className="form-label-ms" htmlFor="apellidosInput">Apellidos:</label>
              <input
                id="apellidosInput"
                type="text"
                className="form-control-ms"
                value={apellidos}
                onChange={(e) => setApellidos(e.target.value)}
              />
            </div>

            {/* Celular */}
            <div className="col-md-6-ms">
              <label className="form-label-ms" htmlFor="celularInput">Celular:</label>
              <input
                id="celularInput"
                type="text"
                className="form-control-ms"
                value={celular}
                onChange={(e) => setCelular(e.target.value)}
              />
            </div>

            {/* Contraseña */}
            <div className="col-md-6-ms">
              <label className="form-label-ms" htmlFor="passwordInput">Contraseña:</label>
              <input
                id="passwordInput"
                type="password"
                className="form-control-ms"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {/* Documentos */}
            <div className="col-md-6-ms">
              <label className="form-label-ms" htmlFor="docsInput">
                Documento(s) de Identidad:
              </label>
              <input
                id="docsInput"
                type="file"
                className="form-control-file-ms"
                multiple
                onChange={handleFileChange}
              />
              <small style={{ fontSize: '0.8rem', color: '#666' }}>
                Puedes subir varias fotos (frontal, reverso, etc.)
              </small>
            </div>
          </div>
          
          <button type="submit" className="btn-ms-primary">
            Registrar
          </button>
        </form>

        <p className="ms-register-link">
          ¿Ya tienes cuenta? <Link to="/salidaskfc.login">Inicia sesión</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
