import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';

import Login from './pages/Login';
import Register from './pages/Register';
import DashboardAdmin from './pages/DashboardAdmin';
import DashboardEncargado from './pages/DashboardEncargado';
import DashboardSeguridad from './pages/DashboardSeguridad';
import SalidasList from './pages/SalidasList';
import SalidaCreate from './pages/SalidaCreate';
import SalidaSearch from './pages/SalidaSearch';
import UserManagement from './pages/UserManagement';
import MisSalidas from './pages/MisSalidas';
import AprobarSalidasPendientes from './pages/AprobarSalidasPendientes.jsx';

function App() {
  const { user } = useAuth();

  return (
    <BrowserRouter>
      {/* Navbar sólo si user existe */}
      {user && <Navbar />}

      <Routes>
        {/* LOGIN => si user existe => redirige a su dashboard, si no => <Login/> */}
        <Route
          path="/salidaskfc.login"
          element={
            user ? (
              user.rol === 'administrador' ? (
                <Navigate to="/dashboard-admin" replace />
              ) : user.rol === 'encargado' ? (
                <Navigate to="/dashboard-encargado" replace />
              ) : user.rol === 'seguridad' ? (
                <Navigate to="/dashboard-seguridad" replace />
              ) : (
                <Navigate to="/salidaskfc.login" replace />
              )
            ) : (
              <Login />
            )
          }
        />

        {/* REGISTER => si user ya existe => redirige a su dashboard */}
        <Route
          path="/register"
          element={
            user ? (
              user.rol === 'administrador' ? (
                <Navigate to="/dashboard-admin" replace />
              ) : user.rol === 'encargado' ? (
                <Navigate to="/dashboard-encargado" replace />
              ) : user.rol === 'seguridad' ? (
                <Navigate to="/dashboard-seguridad" replace />
              ) : (
                <Navigate to="/salidaskfc.login" replace />
              )
            ) : (
              <Register />
            )
          }
        />

        {/* RUTA RAÍZ => si no hay user => /login, si hay user => su dashboard */}
        <Route
          path="/"
          element={
            !user ? (
              <Navigate to="/salidaskfc.login" replace />
            ) : user.rol === 'administrador' ? (
              <Navigate to="/dashboard-admin" replace />
            ) : user.rol === 'encargado' ? (
              <Navigate to="/dashboard-encargado" replace />
            ) : user.rol === 'seguridad' ? (
              <Navigate to="/dashboard-seguridad" replace />
            ) : (
              <h1>Rol desconocido</h1>
            )
          }
        />

        {/* RUTAS DE DASHBOARDS EXPLÍCITAS */}
        <Route
          path="/dashboard-admin"
          element={
            <ProtectedRoute rolesAllowed={['administrador']}>
              <DashboardAdmin />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard-encargado"
          element={
            <ProtectedRoute rolesAllowed={['encargado']}>
              <DashboardEncargado />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard-seguridad"
          element={
            <ProtectedRoute rolesAllowed={['seguridad']}>
              <DashboardSeguridad />
            </ProtectedRoute>
          }
        />

        {/* DEMÁS RUTAS PRIVADAS */}
        <Route
          path="/admin/aprobar"
          element={
            <ProtectedRoute rolesAllowed={['administrador']}>
              <AprobarSalidasPendientes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <ProtectedRoute rolesAllowed={['administrador']}>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/mis-salidas"
          element={
            <ProtectedRoute rolesAllowed={['administrador','encargado','seguridad']}>
              <MisSalidas />
            </ProtectedRoute>
          }
        />

        <Route
          path="/salidas"
          element={
            <ProtectedRoute rolesAllowed={['administrador', 'encargado', 'seguridad']}>
              <SalidasList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/salidas/crear"
          element={
            <ProtectedRoute rolesAllowed={['administrador','encargado']}>
              <SalidaCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/salidas/search"
          element={
            <ProtectedRoute rolesAllowed={['administrador','encargado','seguridad']}>
              <SalidaSearch />
            </ProtectedRoute>
          }
        />

        {/* 404 */}
        <Route path="*" element={<h1>404 - Página no encontrada</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
