import { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import './SalidaCreate.css';

// Componente principal de creación de salida
const SalidaCreate = () => {

  // ========= ESTADOS GENERALES =========
  const [motivo, setMotivo] = useState('');
  const [items, setItems] = useState([]);          
  const [usuarios, setUsuarios] = useState([]);    
  const [destinos, setDestinos] = useState([]);    
  const [msg, setMsg] = useState('');
  const [error, setError] = useState('');
  const [modalImage, setModalImage] = useState(null);

  // ========= FILTRO DE RESPONSABLES =========
  const [filtroResponsable, setFiltroResponsable] = useState('');
  const [mostrarResponsables, setMostrarResponsables] = useState(true);

  // Inicializa tooltips de Bootstrap
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((el) => new window.bootstrap.Tooltip(el));
  }, []);

  // Cargar usuarios y destinos al montar
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [uRes, dRes] = await Promise.all([
          axiosInstance.get('/users/all'),
          axiosInstance.get('/destinos')
        ]);
        setUsuarios(uRes.data || []);
        setDestinos(dRes.data || []);
      } catch (err) {
        console.error(err);
        setError('Error al cargar usuarios/destinos');
      }
    };
    fetchData();
  }, []);

  // ========= AGREGAR ITEM =========
  const handleAddItem = () => {
    setItems((prev) => [
      ...prev,
      {
        equipo: {
          nombre: '',
          marca: '',
          modelo: '',
          serie: 'N/A',    
          descripcion: ''
        },
        idDestino: '',
        cantidad: 1,
        responsables: [],
        files: [],
        selected: false
      }
    ]);
  };

  // ========= ELIMINAR ITEM =========
  const handleRemoveItem = (idx) => {
    const updated = [...items];
    updated.splice(idx, 1);
    setItems(updated);
  };

  // ========= SELECCIONAR UN SOLO ITEM =========
  const handleSelectItem = (idx) => {
    const updated = [...items];
    // Desmarcar todos
    updated.forEach((it) => (it.selected = false));
    // Marcar el actual
    updated[idx].selected = true;
    setItems(updated);
  };

  // ========= DUPLICAR EL ITEM SELECCIONADO =========
  const handleDuplicateSelected = () => {
    const selectedIndex = items.findIndex((it) => it.selected);
    if (selectedIndex === -1) {
      setError('No hay un ítem seleccionado para duplicar');
      return;
    }
    const original = items[selectedIndex];
    const clone = {
      equipo: {
        nombre: original.equipo.nombre,
        marca: original.equipo.marca,
        modelo: original.equipo.modelo,
        serie: 'N/A',
        descripcion: original.equipo.descripcion
      },
      idDestino: '',
      cantidad: 1,
      responsables: [...original.responsables],
      files: [],
      selected: false
    };
    setItems((prev) => [...prev, clone]);
  };

  // ========= CAMBIO EN CAMPOS (destino, cantidad) =========
  const handleItemChange = (idx, field, value) => {
    const updated = [...items];
    updated[idx][field] = value;
    setItems(updated);
  };

  // ========= CAMBIO EN CAMPOS DEL EQUIPO =========
  const handleEquipoChange = (idx, eqField, eqValue) => {
    const updated = [...items];
    const eq = updated[idx].equipo;
    if (eqField === 'serie') {
      eq[eqField] = eqValue;
      if (eqValue !== 'N/A' && eqValue.trim() !== '') {
        updated[idx].cantidad = 1;
      }
    } else {
      eq[eqField] = eqValue;
    }
    updated[idx].equipo = eq;
    setItems(updated);
  };

  // ========= MANEJO DE RESPONSABLES (CHECK) EN UN COMBO FILTRADO =========
  const handleResponsableToggle = (idx, userId) => {
    const updated = [...items];
    const arr = updated[idx].responsables;
    if (arr.includes(userId)) {
      updated[idx].responsables = arr.filter((r) => r !== userId);
    } else {
      updated[idx].responsables = [...arr, userId];
    }
    setItems(updated);
  };

  // ========= SUBIR FOTOS =========
  const handleFileChange = (idx, e) => {
    const fileArray = Array.from(e.target.files);
    const updated = [...items];
    updated[idx].files = [...updated[idx].files, ...fileArray];
    setItems(updated);
  };

  // Eliminar foto
  const handleRemovePhoto = (idx, fileIndex) => {
    const updated = [...items];
    updated[idx].files.splice(fileIndex, 1);
    setItems(updated);
  };

  // Preview de foto en modal
  /*const handlePreview = (file) => {
    const url = URL.createObjectURL(file);
    setModalImage(url);
    const modal = new window.bootstrap.Modal(
      document.getElementById('imageModal')
    );
    modal.show();
  };*/

  // ========= GUARDAR SALIDA =========
  const handleSubmit = async () => {
    setError('');
    setMsg('');

    // Validar motivo
    if (!motivo.trim()) {
      setError('El motivo es obligatorio');
      return;
    }
    if (items.length === 0) {
      setError('Agrega al menos un ítem');
      return;
    }
    // Validar items
    for (let i=0; i<items.length; i++){
      const it = items[i];
      if (!it.equipo.nombre.trim() ||
          !it.equipo.marca.trim() ||
          !it.equipo.modelo.trim() ||
          !it.equipo.descripcion.trim()){
        setError(`Faltan campos obligatorios en el ítem #${i+1}`);
        return;
      }
      if (!it.idDestino){
        setError(`El destino es obligatorio en el ítem #${i+1}`);
        return;
      }
      if (it.responsables.length === 0){
        setError(`Selecciona al menos un responsable en el ítem #${i+1}`);
        return;
      }
    }

    const salidaInfo = { motivo, items: [] };
    const allFiles = [];
    let fileCounter = 0;

    items.forEach((it) => {
      const fotoIndex = [];
      it.files.forEach(() => {
        fotoIndex.push(fileCounter);
        fileCounter++;
      });
      salidaInfo.items.push({
        equipo: { ...it.equipo },
        idDestino: Number(it.idDestino),
        cantidad: Number(it.cantidad) || 1,
        responsables: it.responsables.map(Number),
        fotoIndex
      });
      allFiles.push(...it.files);
    });

    const formData = new FormData();
    formData.append('salidaInfo', JSON.stringify(salidaInfo));
    allFiles.forEach((f) => {
      formData.append('fotos', f);
    });

    try {
      const { data } = await axiosInstance.post('/salidas', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setMsg(`Salida creada con éxito. ID: ${data.idSalida}`);
      setMotivo('');
      setItems([]);
    } catch (err) {
      console.error(err);
      setError('Error al crear la salida');
    }
  };

  // ========= FILTRO DE RESPONSABLES (lista) =========
  const usuariosFiltrados = usuarios.filter((u) => {
    const fullName = (u.NOMBRES_USUARIO + ' ' + u.APELLIDOS_USUARIO).toLowerCase();
    return fullName.includes(filtroResponsable.toLowerCase()) ||
           u.CEDULA_USUARIO.includes(filtroResponsable);
  });

  return (
    <div className="sc-container">
      {/* SIDEBAR IZQUIERDA */}
      <div className="sc-sidebar d-flex flex-column align-items-center">
        
        {/* Botón Agregar Ítem con icono y tooltip */}
        <button
          type="button"
          className="btn btn-primary mb-2"
          onClick={handleAddItem}
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Agregar Ítem"
        >
          <i className="bi bi-plus-square fs-4"></i>
        </button>

        {/* Botón Duplicar (solo 1 item a la vez) con icono y tooltip */}
        <button
          type="button"
          className="btn btn-info mb-2"
          onClick={handleDuplicateSelected}
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Duplicar Ítem"
        >
          <i className="bi bi-files fs-4"></i>
        </button>

        {/* Botón Guardar con icono y tooltip */}
        <button
          type="button"
          className="btn btn-success"
          onClick={handleSubmit}
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Guardar Salida"
        >
          <i className="bi bi-save fs-4"></i>
        </button>

      </div>

      {/* CONTENIDO PRINCIPAL */}
      <div className="sc-content">
        {/* NAVBAR ENCIMA */}
        <nav className="navbar navbar-light bg-light px-3 mb-3">
          <span className="navbar-brand">Salida Equipos</span>
          {/* Podrías poner link a logout */}
        </nav>      

        {/* Errores y mensajes */}
        {error && <div className="alert alert-danger">{error}</div>}
        {msg && <div className="alert alert-success">{msg}</div>}

        {/* MOTIVO */}
        <div className="mb-3">
          <label className="form-label fw-bold">Motivo:</label>
          <textarea
            className="form-control"
            rows={2}
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
          />
        </div>

        {/* Filtro de Responsables: Input + Toggle */}
        <div className="mb-3 d-flex align-items-center">
          <label className="form-label fw-bold me-2">Filtrar responsables:</label>
          <input
            type="text"
            className="form-control w-auto me-2"
            placeholder="Buscar nombre o cédula..."
            value={filtroResponsable}
            onChange={(e) => setFiltroResponsable(e.target.value)}
          />
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setMostrarResponsables(!mostrarResponsables)}
          >
            {mostrarResponsables ? 'Ocultar' : 'Mostrar'} lista
          </button>
        </div>

        {/* LISTA DE ITEMS */}
        {items.map((it, idx) => (
          <div key={idx} className="card mb-4 shadow sc-item-card">
            <div className="card-header d-flex justify-content-between align-items-center">
              {/* Check para seleccionar item (solo 1) */}
              <div>
                <input
                  type="checkbox"
                  className="form-check-input me-2"
                  checked={it.selected}
                  onChange={() => handleSelectItem(idx)}
                />
                <strong>Ítem #{idx + 1}</strong>
              </div>
              {/* Botón X para eliminar ítem */}
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => handleRemoveItem(idx)}
              >
                X
              </button>
            </div>

            {/* CUERPO DEL ITEM */}
            <div className="card-body">
              <div className="row g-3">
                {/* Nombre */}
                <div className="col-md-4">
                  <label className="form-label">Nombre:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={it.equipo.nombre}
                    onChange={(e) => handleEquipoChange(idx, 'nombre', e.target.value)}
                  />
                </div>
                {/* Marca */}
                <div className="col-md-4">
                  <label className="form-label">Marca:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={it.equipo.marca}
                    onChange={(e) => handleEquipoChange(idx, 'marca', e.target.value)}
                  />
                </div>
                {/* Modelo */}
                <div className="col-md-4">
                  <label className="form-label">Modelo:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={it.equipo.modelo}
                    onChange={(e) => handleEquipoChange(idx, 'modelo', e.target.value)}
                  />
                </div>

                {/* Serie */}
                <div className="col-md-6">
                  <label className="form-label">Serie:</label>
                  <textarea
                    className="form-control"
                    rows={2}
                    placeholder="N/A o texto extenso"
                    value={it.equipo.serie}
                    onChange={(e) => handleEquipoChange(idx, 'serie', e.target.value)}
                  />
                  {/* if != 'N/A' => forzar cantidad=1 (ya lo hacemos en handleEquipoChange) */}
                  {it.equipo.serie !== 'N/A' && it.equipo.serie.trim() !== '' && (
                    <small className="text-info">
                      Cantidad se forzará a 1 por tener serie
                    </small>
                  )}
                </div>

                {/* Descripción */}
                <div className="col-md-6">
                  <label className="form-label">Descripción:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={it.equipo.descripcion}
                    onChange={(e) => handleEquipoChange(idx, 'descripcion', e.target.value)}
                  />
                </div>

                {/* Destino */}
                <div className="col-md-6">
                  <label className="form-label fw-bold">Destino:</label>
                  <select
                    className="form-select"
                    value={it.idDestino}
                    onChange={(e) => handleItemChange(idx, 'idDestino', e.target.value)}
                  >
                    <option value="">--Seleccionar--</option>
                    {destinos.map((d) => (
                      <option key={d.ID_DESTINO} value={d.ID_DESTINO}>
                        {d.NOMBRE_DESTINO}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Cantidad (deshabilitado si serie != 'N/A' y no vacía) */}
                <div className="col-md-6">
                  <label className="form-label fw-bold">Cantidad:</label>
                  <input
                    type="number"
                    min="1"
                    className="form-control"
                    disabled={
                      it.equipo.serie !== 'N/A' &&
                      it.equipo.serie.trim() !== ''
                    }
                    value={it.cantidad}
                    onChange={(e) => handleItemChange(idx, 'cantidad', e.target.value)}
                  />
                </div>
              </div>

              {/* RESPONSABLES */}
              {mostrarResponsables && (
                <div className="mt-3">
                  <label className="form-label fw-bold">Responsables:</label>
                  <div className="form-control" style={{ minHeight:'60px' }}>
                    {usuariosFiltrados.map((u) => {
                      const userIdStr = String(u.ID_USUARIO);
                      const checked = it.responsables.includes(userIdStr);
                      return (
                        <div key={u.ID_USUARIO}>
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={checked}
                            onChange={() => handleResponsableToggle(idx, userIdStr)}
                          />
                          <label className="form-check-label">
                            {u.NOMBRES_USUARIO} {u.APELLIDOS_USUARIO} (CI: {u.CEDULA_USUARIO})
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* FOTOS */}
              <div className="mt-3">
                <label className="form-label fw-bold">Fotos (múltiples):</label>
                <input
                  type="file"
                  className="form-control"
                  multiple
                  onChange={(e) => handleFileChange(idx, e)}
                />

                {it.files && it.files.length > 0 && (
                  <div className="mt-2 d-flex flex-wrap gap-2">
                    {it.files.map((file, fileIndex) => {
                      const previewUrl = URL.createObjectURL(file);
                      return (
                        <div
                          key={fileIndex}
                          className="position-relative"
                          style={{ width: '100px', height: '100px' }}
                        >
                          <img
                            src={previewUrl}
                            alt="preview"
                            className="img-thumbnail"
                            style={{ width:'100%', height:'100%', objectFit:'cover', cursor:'pointer' }}
                            onClick={() => {
                              setModalImage(previewUrl);
                              const modal = new window.bootstrap.Modal(
                                document.getElementById('imageModal')
                              );
                              modal.show();
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-sm btn-danger position-absolute top-0 end-0"
                            onClick={() => handleRemovePhoto(idx, fileIndex)}
                          >
                            X
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

            </div>
          </div>
        ))}

        {/* MODAL para ver imagen en grande */}
        <div
          className="modal fade"
          id="imageModal"
          tabIndex="-1"
          aria-labelledby="imageModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="imageModalLabel">
                  Vista Previa
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex justify-content-center align-items-center">
                {modalImage && (
                  <img
                    src={modalImage}
                    alt="preview-larger"
                    style={{ maxWidth:'100%', maxHeight:'90vh' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default SalidaCreate;
