import { useEffect, useState, useRef } from 'react';
import axiosInstance from '../api/axiosInstance';
import './AprobarSalidasPendientes.css';

function EstadoAprobacion({ estado }) {
  let adminIcon = '❌';
  let segIcon = '❌';
  if (estado === 'APROBADO_ADMIN' || estado === 'APROBADA') {
    adminIcon = '✅';
  }
  if (estado === 'APROBADA') {
    segIcon = '✅';
  }
  return (
    <p className="aprobacion-estado">
      <strong>Admin:</strong> {adminIcon} &nbsp;|&nbsp;
      <strong>Seguridad:</strong> {segIcon}
    </p>
  );
}

const AprobarSalidasPendientes = () => {
  const [salidas, setSalidas] = useState([]);
  const [detalle, setDetalle] = useState(null);    // guardamos la info detallada
  const [error, setError] = useState('');

  // Modal references
  const detailModalRef = useRef(null);  // para el modal de detalle
  const photoModalRef = useRef(null);   // para el modal de foto
  const [modalImage, setModalImage] = useState(null); // foto a ver en photoModal

  // Al montar, inicializamos "detailModalRef" y "photoModalRef"
  useEffect(() => {
    detailModalRef.current = new window.bootstrap.Modal(document.getElementById('detailModal'));
    photoModalRef.current  = new window.bootstrap.Modal(document.getElementById('photoModal'));
  }, []);

  // Cargar las salidas PENDIENTE (admin)
  useEffect(() => {
    fetchPendientes();
  }, []);

  const fetchPendientes = async () => {
    setError('');
    setDetalle(null);
    try {
      const { data } = await axiosInstance.get('/salidas/filter?f=pend-admin');
      setSalidas(data.salidas || []);
    } catch (err) {
      console.error(err);
      setError('Error al cargar salidas pendientes');
    }
  };

  // ========== Ver Detalle (toggle) ==========
  const handleVerDetalle = async (idSalida) => {
    // Si ya estamos mostrando ESE MISMO detalle => ocultamos (cerramos modal)
    if (detalle && detalle.salida.ID_SALIDA === idSalida) {
      closeDetailModal();
      return;
    }
    // Caso contrario, pedimos la data y abrimos modal
    try {
      setError('');
      const { data } = await axiosInstance.get(`/salidas/${idSalida}/detalles`);
      setDetalle(data);
      // Abrimos modal de detalle
      detailModalRef.current.show();
    } catch (err) {
      console.error(err);
      setError('Error al obtener detalle');
    }
  };

  // Cerrar modal => setDetalle(null)
  const closeDetailModal = () => {
    detailModalRef.current.hide();
    setDetalle(null);
  };

  // Escucha evento "hidden.bs.modal" para cuando se cierra el modal por fuera
  useEffect(() => {
    const detailEl = document.getElementById('detailModal');
    detailEl.addEventListener('hidden.bs.modal', () => {
      setDetalle(null); // limpimos al cerrar manualmente
    });
  }, []);

  // Aprobar por administrador
  const handleAprobarAdmin = async (idSalida) => {
    setError('');
    try {
      detailModalRef.current.hide();
      setDetalle(null);
      await axiosInstance.put(`/salidas/approve/admin/${idSalida}`);
      alert('Salida aprobada por administrador');
      setSalidas((prev) => prev.filter((s) => s.ID_SALIDA !== idSalida));
    } catch (err) {
      console.error(err);
      setError('Error al aprobar la salida');
    }
  };

  // Convertir varbinary => blob
  const varbinaryToBlobURL = (vbArray) => {
    if (!vbArray) return null;
    const arr = vbArray.data || vbArray;
    if (!arr) return null;
    const u8 = new Uint8Array(arr);
    const blob = new Blob([u8], { type: 'image/jpeg' });
    return URL.createObjectURL(blob);
  };

  // Abrir modal de foto
  const handlePreviewPhoto = (url) => {
    setModalImage(url);
    photoModalRef.current.show();
  };

  // Al cerrar photoModal
  const closePhotoModal = () => {
    photoModalRef.current.hide();
    setModalImage(null);
  };

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">Salidas Pendientes de Aprobación (Admin)</h2>

      {error && <div className="alert alert-danger">{error}</div>}

      {/* Lista de salidas en cards */}
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
        {salidas.map((s) => (
          <div className="col" key={s.ID_SALIDA}>
            <div className="card h-100 shadow">
              <div className="card-body d-flex flex-column justify-content-between">
                <div>
                  <h5 className="card-title mb-2">
                    Salida #{s.ID_SALIDA}
                  </h5>
                  <p className="card-text mb-1">
                    <strong>Fecha:</strong> {s.FECHA_SALIDA}
                  </p>
                  <p className="card-text mb-1">
                    <strong>Estado:</strong> {s.ESTADO_SALIDA}
                  </p>
                  <p className="card-text">
                    <strong>Creador:</strong> {s.NOMBRES_CREADOR} {s.APELLIDOS_CREADOR}<br/>
                    <small>C.I: {s.CEDULA_CREADOR} | Cel: {s.CELULAR_CREADOR || '--'}</small>
                  </p>
                </div>
                <div className="mt-3 d-flex gap-2">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleVerDetalle(s.ID_SALIDA)}
                  >
                    Ver Detalle
                  </button>
                  {s.ESTADO_SALIDA === 'PENDIENTE' && (
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleAprobarAdmin(s.ID_SALIDA)}
                    >
                      Aprobar
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* MODAL (Fullscreen) para el Detalle */}
      <div
        className="modal fade"
        id="detailModal"
        tabIndex="-1"
        aria-labelledby="detailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div className="modal-content">
            {detalle && (
              <>
                <div className="modal-header">
                  <h5 className="modal-title" id="detailModalLabel">
                    Detalle de la Salida #{detalle.salida.ID_SALIDA}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body overflow-auto">
                  {/* Contenido del detalle en fullscreen */}
                  <p>
                    <strong>Fecha/Hora:</strong> {detalle.salida.FECHA_SALIDA}
                  </p>
                  <p>
                    <strong>Creador:</strong> {detalle.salida.NOMBRES_CREADOR} {detalle.salida.APELLIDOS_CREADOR}<br/>
                    <small>
                      C.I: {detalle.salida.CEDULA_CREADOR} | Cel: {detalle.salida.CELULAR_CREADOR || '--'}
                    </small>
                  </p>
                  
                  {/* Documentos del Creador */}
                  {detalle.salida.docsId && detalle.salida.docsId.length > 0 && (
                    <div className="mb-3">
                      <strong>Documentos de Identidad (Creador):</strong>
                      <div className="d-flex gap-2 flex-wrap mt-2">
                        {detalle.salida.docsId.map((docBin, idx) => {
                          const url = varbinaryToBlobURL(docBin);
                          if (!url) return null;
                          return (
                            <img
                              key={idx}
                              src={url}
                              alt="DocIdentidadCreador"
                              className="img-thumbnail"
                              style={{ width:'100px', cursor:'pointer' }}
                              onClick={() => handlePreviewPhoto(url)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {/* Estado (Admin/Seg) */}
                  <EstadoAprobacion estado={detalle.salida.ESTADO_SALIDA} />

                  {/* Admin que aprobó */}
                  {detalle.salida.ID_ADMIN && (
                    <p>
                      <strong>Administrador que aprobó:</strong>{' '}
                      {detalle.salida.NOMBRES_ADMIN} {detalle.salida.APELLIDOS_ADMIN}<br/>
                      <small>Cel: {detalle.salida.CELULAR_ADMIN || '--'}</small>
                    </p>
                  )}
                  {/* Seguridad que aprobó */}
                  {detalle.salida.ID_SEGURIDAD && (
                    <p>
                      <strong>Agente de seguridad:</strong>{' '}
                      {detalle.salida.NOMBRES_SEGURIDAD} {detalle.salida.APELLIDOS_SEGURIDAD}<br/>
                      <small>Cel: {detalle.salida.CELULAR_SEGURIDAD || '--'}</small>
                    </p>
                  )}

                  {/* Items */}
                  {detalle.detalles.length === 0 ? (
                    <p>No hay ítems en esta salida.</p>
                  ) : (
                    detalle.detalles.map((d, idx) => (
                      <div key={idx} className="border p-2 mb-3">
                        <h5>
                          Equipo: {d.NOMBRE_EQUIPO} ({d.MARCA_EQUIPO}-{d.MODELO_EQUIPO})
                        </h5>
                        <p><strong>Serie:</strong> {d.SERIE_EQUIPO}</p>
                        <p><strong>Descripción:</strong> {d.DESCRIPCION_EQUIPO}</p>
                        <p><strong>Cantidad:</strong> {d.CANTIDAD}</p>
                        <p><strong>Destino:</strong> {d.NOMBRE_DESTINO}</p>

                        {/* Responsables */}
                        {d.responsables && d.responsables.length > 0 && (
                          <div>
                            <strong>Responsables:</strong>
                            <ul className="mb-2">
                              {d.responsables.map((r) => (
                                <li key={r.ID_USUARIO}>
                                  {r.NOMBRES_USUARIO} {r.APELLIDOS_USUARIO}
                                  (C.I:{r.CEDULA_USUARIO})
                                  {/* Documentos ID del responsable */}
                                  {r.docsId && r.docsId.length > 0 && (
                                    <div className="mt-1 d-flex gap-2 flex-wrap">
                                      <strong>Docs:</strong>
                                      {r.docsId.map((docBin, docIdx) => {
                                        const url = varbinaryToBlobURL(docBin);
                                        if (!url) return null;
                                        return (
                                          <img
                                            key={docIdx}
                                            src={url}
                                            alt="DocIdentidadResp"
                                            className="img-thumbnail"
                                            style={{ width:'80px', cursor:'pointer' }}
                                            onClick={() => handlePreviewPhoto(url)}
                                          />
                                        );
                                      })}
                                    </div>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {/* Fotos del item */}
                        {d.fotos && d.fotos.length > 0 && (
                          <div>
                            <strong>Fotos del Ítem:</strong><br/>
                            <div className="d-flex gap-2 flex-wrap mt-1">
                              {d.fotos.map((f) => {
                                const url = varbinaryToBlobURL(f.IMAGEN_FOTO);
                                if (!url) return null;
                                return (
                                  <img
                                    key={f.ID_FOTO}
                                    src={url}
                                    alt={`FotoItem${f.ID_FOTO}`}
                                    className="img-thumbnail"
                                    style={{ width:'80px', cursor:'pointer' }}
                                    onClick={() => handlePreviewPhoto(url)}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  )}
                </div>
                <div className="modal-footer">
                  {detalle.salida.ESTADO_SALIDA === 'PENDIENTE' && (
                    <button
                      className="btn btn-success"
                      onClick={() => handleAprobarAdmin(detalle.salida.ID_SALIDA)}
                    >
                      Aprobar
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={closeDetailModal}
                  >
                    Cerrar
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* MODAL para foto fullscreen */}
      <div
        className="modal fade"
        id="photoModal"
        tabIndex="-1"
        aria-labelledby="photoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="photoModalLabel">Vista Previa</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closePhotoModal}
              ></button>
            </div>
            <div className="modal-body d-flex justify-content-center align-items-center">
              {modalImage && (
                <img
                  src={modalImage}
                  alt="fullscreen"
                  style={{ maxWidth:'100%', maxHeight:'90vh' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AprobarSalidasPendientes;
