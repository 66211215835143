// src/pages/MisSalidas.jsx
import { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import './MisSalidas.css'; // si quieres estilos

function MisSalidas() {
  const [salidas, setSalidas] = useState([]);
  const [detalle, setDetalle] = useState(null);
  const [error, setError] = useState('');

  // 1. Cargar las salidas donde el usuario participó
  const fetchMisSalidas = async () => {
    setError('');
    setDetalle(null);
    try {
      const { data } = await axiosInstance.get('/salidas/mis-salidas');
      setSalidas(data.salidas || []);
    } catch (err) {
      console.error(err);
      setError('Error al cargar tus salidas');
    }
  };

  useEffect(() => {
    fetchMisSalidas();
  }, []);

  // 2. Ver detalle => /salidas/:id/detalles
  const handleVerDetalle = async (idSalida) => {
    setError('');
    setDetalle(null);
    try {
      const { data } = await axiosInstance.get(`/salidas/${idSalida}/detalles`);
      setDetalle(data);
    } catch (err) {
      console.error(err);
      setError('Error al cargar detalle');
    }
  };

  // 3. Convertir varbinary => blob
  const varbinaryToBlobURL = (vbArray) => {
    if (!vbArray) return null;
    const arr = vbArray.data || vbArray;
    if (!arr) return null;
    const u8 = new Uint8Array(arr);
    const blob = new Blob([u8], { type: 'image/jpeg' });
    return URL.createObjectURL(blob);
  };

  return (
    <div className="mis-salidas-container">
      <h2>Mis Salidas</h2>
      {error && <p className="error-msg">{error}</p>}

      <table className="mis-salidas-table" border="1" cellPadding="5">
        <thead>
          <tr>
            <th>ID Salida</th>
            <th>Fecha</th>
            <th>Estado</th>
            <th>Creado por (Cel)</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {salidas.map((s) => (
            <tr key={s.ID_SALIDA}>
              <td>{s.ID_SALIDA}</td>
              <td>{s.FECHA_SALIDA}</td>
              <td>{s.ESTADO_SALIDA}</td>
              <td>
                {s.NOMBRES_CREADOR} {s.APELLIDOS_CREADOR}<br/>
                C.I:{s.CEDULA_CREADOR}<br/>
                Cel:{s.CELULAR_CREADOR || '--'}
              </td>
              <td>
                <button onClick={() => handleVerDetalle(s.ID_SALIDA)}>Ver Detalle</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Mostrar Detalle */}
      {detalle && (
        <div className="detalle-salida">
          <h3>Detalle de la Salida #{detalle.salida.ID_SALIDA}</h3>
          <p>Fecha: {detalle.salida.FECHA_SALIDA}</p>
          <p>
            <strong>Creador:</strong> {detalle.salida.NOMBRES_CREADOR}{' '}
            {detalle.salida.APELLIDOS_CREADOR} <br/>
            C.I:{detalle.salida.CEDULA_CREADOR} <br/>
            Cel:{detalle.salida.CELULAR_CREADOR || '--'}
          </p>
          <p>Estado: {detalle.salida.ESTADO_SALIDA}</p>

          {/* Admin / Seguridad etc. */}
          {detalle.salida.ID_ADMIN && (
            <p>
              <strong>Admin:</strong> {detalle.salida.NOMBRES_ADMIN}{' '}
              {detalle.salida.APELLIDOS_ADMIN} - Cel:{detalle.salida.CELULAR_ADMIN || '--'}
            </p>
          )}
          {detalle.salida.ID_SEGURIDAD && (
            <p>
              <strong>Seguridad:</strong> {detalle.salida.NOMBRES_SEGURIDAD}{' '}
              {detalle.salida.APELLIDOS_SEGURIDAD} - Cel:{detalle.salida.CELULAR_SEGURIDAD || '--'}
            </p>
          )}

          {detalle.detalles.length === 0 ? (
            <p>No hay ítems en esta salida.</p>
          ) : (
            detalle.detalles.map((d, idx) => (
              <div key={idx} className="detalle-item">
                <h4>
                  Equipo: {d.NOMBRE_EQUIPO} ({d.MARCA_EQUIPO}-{d.MODELO_EQUIPO})
                </h4>
                <p>Serie: {d.SERIE_EQUIPO}</p>
                <p>Descripción: {d.DESCRIPCION_EQUIPO}</p>
                <p>Cantidad: {d.CANTIDAD}</p>
                <p>Destino: {d.NOMBRE_DESTINO}</p>

                {/* Responsables */}
                {d.responsables && d.responsables.length > 0 && (
                  <div>
                    <strong>Responsables:</strong>
                    <ul>
                      {d.responsables.map((r) => (
                        <li key={r.ID_USUARIO}>
                          {r.NOMBRES_USUARIO} {r.APELLIDOS_USUARIO} (C.I:{r.CEDULA_USUARIO})

                          {/* Documentos ID si existen */}
                          {r.docsId && r.docsId.length > 0 && (
                            <div style={{ marginTop:'5px' }}>
                              <strong>Documentos de Identidad (solo visible a Admin/Seguridad):</strong>
                              <div style={{ display:'flex', gap:'5px', marginTop:'5px' }}>
                                {r.docsId.map((docBin, docIdx) => {
                                  const url = varbinaryToBlobURL(docBin);
                                  return (
                                    <img
                                      key={docIdx}
                                      src={url}
                                      alt="DocIdentidad"
                                      style={{width:'100px', border:'1px solid #ccc'}}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Fotos del item */}
                {d.fotos && d.fotos.length > 0 && (
                  <div>
                    <strong>Fotos:</strong>
                    <br/>
                    {d.fotos.map((f) => {
                      const url = varbinaryToBlobURL(f.IMAGEN_FOTO);
                      if (!url) return null;
                      return (
                        <img
                          key={f.ID_FOTO}
                          src={url}
                          alt={`Foto ${f.ID_FOTO}`}
                          className="item-foto"
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default MisSalidas;
