// src/App.jsx
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';

import Login from './pages/Login';
import Register from './pages/Register';
import DashboardAdmin from './pages/DashboardAdmin';
import DashboardEncargado from './pages/DashboardEncargado';
import DashboardSeguridad from './pages/DashboardSeguridad';
import SalidasList from './pages/SalidasList';
import SalidaCreate from './pages/SalidaCreate';
import SalidaSearch from './pages/SalidaSearch';
import UserManagement from './pages/UserManagement';
import MisSalidas from './pages/MisSalidas';

// IMPORTAMOS (sin llaves) 
import AprobarSalidasPendientes from './pages/AprobarSalidasPendientes.jsx';

function App() {
  const { user } = useAuth();

  return (
    <BrowserRouter>
      <Navbar />

      <Routes>
        {/* Rutas públicas */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Rutas privadas con ProtectedRoute */}
        <Route 
          path="/admin" 
          element={
            <ProtectedRoute rolesAllowed={['administrador']}>
              <DashboardAdmin />
            </ProtectedRoute>
          }
        />

        {/* Nueva ruta para aprobar pendientes */}
        <Route
          path="/admin/aprobar"
          element={
            <ProtectedRoute rolesAllowed={['administrador']}>
              <AprobarSalidasPendientes />
            </ProtectedRoute>
          }
        />

<Route 
  path="/admin/users"
  element={
    <ProtectedRoute rolesAllowed={['administrador']}>
      <UserManagement />
    </ProtectedRoute>
  }
/>

<Route
  path="/mis-salidas"
  element={
    <ProtectedRoute rolesAllowed={['administrador','encargado','seguridad']}>
      <MisSalidas />
    </ProtectedRoute>
  }
/>


        <Route 
          path="/encargado"
          element={
            <ProtectedRoute rolesAllowed={['encargado']}>
              <DashboardEncargado />
            </ProtectedRoute>
          }
        />
        <Route 
          path="/seguridad"
          element={
            <ProtectedRoute rolesAllowed={['seguridad']}>
              <DashboardSeguridad />
            </ProtectedRoute>
          }
        />

        {/* Listar salidas */}
        <Route 
          path="/salidas" 
          element={
            <ProtectedRoute rolesAllowed={['administrador', 'encargado', 'seguridad']}>
              <SalidasList />
            </ProtectedRoute>
          }
        />

        {/* Crear salida */}
        <Route
          path="/salidas/crear"
          element={
            <ProtectedRoute rolesAllowed={['administrador', 'encargado']}>
              <SalidaCreate />
            </ProtectedRoute>
          }
        />

        {/* Búsqueda */}
        <Route
          path="/salidas/search"
          element={
            <ProtectedRoute rolesAllowed={['administrador', 'encargado', 'seguridad']}>
              <SalidaSearch />
            </ProtectedRoute>
          }
        />

        {/* Ruta raíz */}
        <Route 
          path="/" 
          element={
            user ? (
              user.rol === 'administrador' ? (
                <DashboardAdmin />
              ) : user.rol === 'encargado' ? (
                <DashboardEncargado />
              ) : user.rol === 'seguridad' ? (
                <DashboardSeguridad />
              ) : (
                <h1>Rol desconocido</h1>
              )
            ) : (
              <Login />
            )
          }
        />

        {/* 404 */}
        <Route path="*" element={<h1>404 - Página no encontrada</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
