import { useEffect, useState, useRef } from 'react';
import axiosInstance from '../api/axiosInstance';
import './UserManagement.css';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [viewingUser, setViewingUser] = useState(null);
  const [resetPassUser, setResetPassUser] = useState(null);

  const [roles, setRoles] = useState([]);
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');

  const [userDocs, setUserDocs] = useState([]);
  const [newDocs, setNewDocs] = useState([]);
  const [newPassword, setNewPassword] = useState('');

  // Filtro de estado
  const [filterState, setFilterState] = useState('all');
  // Filtro de cédula
  const [cedulaFilter, setCedulaFilter] = useState('');

  // Modal de fotos en fullscreen
  const [modalImage, setModalImage] = useState(null);
  const photoModalRef = useRef(null);

  // Timer para ocultar mensaje
  const hideMsgTimerRef = useRef(null);

  // Al montar
  useEffect(() => {
    // Inicializar roles (simulación)
    setRoles([
      { id: 1, nombre: 'administrador' },
      { id: 2, nombre: 'seguridad' },
      { id: 3, nombre: 'encargado' }
    ]);
    // Modal de fotos (Bootstrap)
    const modalEl = document.getElementById('photoModal');
    if (modalEl) {
      photoModalRef.current = new window.bootstrap.Modal(modalEl, { keyboard: true });
    }
  }, []);

  // Al desmontar limpiamos timer
  useEffect(() => {
    return () => {
      if (hideMsgTimerRef.current) {
        clearTimeout(hideMsgTimerRef.current);
      }
    };
  }, []);

  // Cargar usuarios con filtros (estado + cédula)
  const fetchUsers = async () => {
    setError('');
    setMsg('');
    try {
      // Construir la URL con query params
      let url = '/users';
      const params = [];
      if (filterState !== 'all') {
        params.push(`estado=${filterState}`);
      }
      if (cedulaFilter.trim() !== '') {
        params.push(`cedula=${cedulaFilter.trim()}`);
      }
      if (params.length > 0) {
        url += '?' + params.join('&');
      }

      const { data } = await axiosInstance.get(url);
      setUsers(data);
    } catch (err) {
      console.error(err);
      setError('Error al cargar usuarios');
    }
  };

  // Cada vez que cambia filterState o cedulaFilter => recargamos
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, [filterState, cedulaFilter]);

  // Mostrar foto en fullscreen
  const handlePreviewPhoto = (url) => {
    setModalImage(url);
    if (photoModalRef.current) {
      photoModalRef.current.show();
    }
  };

  // Borrar mensaje de éxito tras 1.5s
  const showSuccessMsg = (texto) => {
    setMsg(texto);
    if (hideMsgTimerRef.current) {
      clearTimeout(hideMsgTimerRef.current);
    }
    hideMsgTimerRef.current = setTimeout(() => {
      setMsg('');
    }, 1500);
  };

  // ---------- VER DETALLE ----------
  const handleViewClick = async (u) => {
    setMsg('');
    setError('');
    setEditingUser(null);
    setResetPassUser(null);

    setViewingUser(u);

    // Cargar docs
    try {
      const { data } = await axiosInstance.get(`/users/${u.ID_USUARIO}/docs`);
      setUserDocs(data.docs || []);
    } catch (err) {
      console.error(err);
      setError('Error al cargar documentos');
    }
  };
  const handleCloseView = () => {
    setViewingUser(null);
    setUserDocs([]);
  };

  // ---------- EDITAR ----------
  const handleEditClick = async (u) => {
    setMsg('');
    setError('');
    setViewingUser(null);
    setResetPassUser(null);

    // Mapeamos a editingUser
    setEditingUser({
      ...u,
      nombres: u.NOMBRES_USUARIO,
      apellidos: u.APELLIDOS_USUARIO,
      celular: u.CELULAR_USUARIO,
      idRol: u.ID_ROL,
      estado: u.ESTADO_USUARIO
    });

    // Cargar docs
    try {
      const { data } = await axiosInstance.get(`/users/${u.ID_USUARIO}/docs`);
      setUserDocs(data.docs || []);
      setNewDocs([]);
    } catch (err) {
      console.error(err);
      setError('Error al cargar docs');
    }
  };

  const handleSave = async () => {
    setError('');
    setMsg('');
    try {
      await axiosInstance.put(`/users/${editingUser.ID_USUARIO}`, {
        nombres: editingUser.nombres,
        apellidos: editingUser.apellidos,
        celular: editingUser.celular,
        idRol: editingUser.idRol ? Number(editingUser.idRol) : null,
        estado: editingUser.estado
      });
      // Actualizar lista y salir de modo edición
      fetchUsers();
      setEditingUser(null);
      setViewingUser(null);
      setUserDocs([]);
      setNewDocs([]);
      showSuccessMsg('Datos modificados correctamente');
    } catch (err) {
      console.error(err);
      setError('Error al actualizar usuario');
    }
  };

  const handleCancel = () => {
    setEditingUser(null);
    setViewingUser(null);
    setUserDocs([]);
    setNewDocs([]);
  };

  // ---------- DOCS ----------
  const handleDeleteDocs = async () => {
    if (!editingUser) return;
    try {
      await axiosInstance.delete(`/users/${editingUser.ID_USUARIO}/docs`);
      setMsg('Documentos eliminados');
      setUserDocs([]);
      setNewDocs([]);
    } catch (err) {
      console.error(err);
      setError('Error al eliminar documentos');
    }
  };

  const handleFileChange = (e) => {
    setNewDocs(Array.from(e.target.files));
  };
  const handleUploadDocs = async () => {
    if (!editingUser) return;
    if (newDocs.length === 0) {
      setError('No has seleccionado archivos');
      return;
    }
    setError('');
    setMsg('');
    try {
      const formData = new FormData();
      newDocs.forEach((f) => formData.append('docs', f));
      await axiosInstance.put(`/users/${editingUser.ID_USUARIO}/docs`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setMsg('Nuevos documentos subidos');
      const { data } = await axiosInstance.get(`/users/${editingUser.ID_USUARIO}/docs`);
      setUserDocs(data.docs || []);
      setNewDocs([]);
    } catch (err) {
      console.error(err);
      setError('Error al subir documentos');
    }
  };

  const varbinaryToBlobURL = (vbArray) => {
    if (!vbArray) return null;
    const arr = vbArray.data || vbArray;
    if (!arr) return null;
    const u8 = new Uint8Array(arr);
    const blob = new Blob([u8], { type: 'image/jpeg' });
    return URL.createObjectURL(blob);
  };

  // ---------- CAMBIAR CONTRASEÑA ----------
  const handleChangePasswordClick = (u) => {
    setResetPassUser(u);
    setNewPassword('');
    setMsg('');
    setError('');
    setEditingUser(null);
    setViewingUser(null);
    setUserDocs([]);
    setNewDocs([]);
  };

  const handleCancelPassword = () => {
    setResetPassUser(null);
  };

  const handleSavePassword = async () => {
    if (!newPassword) {
      setError('Ingresa la nueva contraseña');
      return;
    }
    try {
      await axiosInstance.put(`/users/${resetPassUser.ID_USUARIO}/password`, {
        newPassword
      });
      // Actualizar la lista, salir de modo pass
      fetchUsers();
      setResetPassUser(null);
      showSuccessMsg('Contraseña actualizada correctamente');
    } catch (err) {
      console.error(err);
      setError('Error al cambiar la contraseña');
    }
  };

  // ---------- RECHAZAR si PENDIENTE = Eliminar ----------
  const handleReject = async (userId) => {
    setError('');
    setMsg('');
    try {
      await axiosInstance.delete(`/users/reject/${userId}`);
      setMsg('Usuario pendiente rechazado/eliminado');
      fetchUsers();
    } catch (err) {
      console.error(err);
      setError('Error al rechazar usuario');
    }
  };

  // ---------- APROBAR si PENDIENTE => ACTIVO ----------
  const handleApprove = async (u) => {
    setError('');
    setMsg('');
    try {
      // update con estado: ACTIVO
      await axiosInstance.put(`/users/${u.ID_USUARIO}`, {
        nombres: u.NOMBRES_USUARIO,
        apellidos: u.APELLIDOS_USUARIO,
        celular: u.CELULAR_USUARIO,
        idRol: u.ID_ROL,
        estado: 'ACTIVO'
      });
      setMsg('Usuario aprobado con éxito');
      fetchUsers();
    } catch (err) {
      console.error(err);
      setError('Error al aprobar usuario');
    }
  };

  // ========== RENDER ==========
  return (
    <div className="container my-4 ms-container">
      {/* Mensajes */}
      {error && <div className="alert alert-danger ms-error">{error}</div>}
      {msg && <div className="alert alert-success ms-success">{msg}</div>}

      {/* Filtros */}
      <div className="d-flex align-items-center gap-3 mb-4 ms-filter flex-wrap">
        {/* Filtro estado */}
        <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2">
          <label className="fw-bold ms-label mb-0">Estado:</label>
          <select
            className="form-select ms-select w-auto"
            value={filterState}
            onChange={(e) => setFilterState(e.target.value)}
          >
            <option value="all">Todos</option>
            <option value="PENDIENTE">Pendientes</option>
            <option value="ACTIVO">Activos</option>
            <option value="INACTIVO">Inactivos</option>
          </select>
        </div>

        {/* Filtro cédula */}
        <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2">
          <label className="fw-bold ms-label mb-0">Cédula:</label>
          <input
            type="text"
            className="form-control ms-textfield w-auto"
            placeholder="Buscar cédula..."
            value={cedulaFilter}
            onChange={(e) => setCedulaFilter(e.target.value)}
          />
        </div>
      </div>

      {/* Ver Detalle */}
      {viewingUser && !editingUser && !resetPassUser && (
        <div className="card mb-4 ms-card ms-vision-line">
          <div className="card-body">
            <h5 className="card-title ms-card-title">
              Detalle de Usuario (ID={viewingUser.ID_USUARIO})
            </h5>
            <p className="ms-field"><strong>Nombres:</strong> {viewingUser.NOMBRES_USUARIO}</p>
            <p className="ms-field"><strong>Apellidos:</strong> {viewingUser.APELLIDOS_USUARIO}</p>
            <p className="ms-field"><strong>Celular:</strong> {viewingUser.CELULAR_USUARIO || '--'}</p>

            {/* Mostrar nombre de Rol */}
            {roles.length > 0 && (
              <p className="ms-field">
                <strong>Rol:</strong>{' '}
                {roles.find(r => r.id === viewingUser.ID_ROL)?.nombre || 'Desconocido'}
              </p>
            )}

            {viewingUser.ESTADO_USUARIO && (
              <p className="ms-field">
                <strong>Estado:</strong> {viewingUser.ESTADO_USUARIO}
              </p>
            )}

            <hr />
            <h6 className="ms-subtitle">Documentos de Identidad:</h6>
            {userDocs.length === 0 ? (
              <p>No hay documentos</p>
            ) : (
              <div className="d-flex flex-wrap gap-2">
                {userDocs.map((doc) => {
                  const url = varbinaryToBlobURL(doc.FOTO_DOC);
                  if (!url) return null;
                  return (
                    <div
                      key={doc.ID_DOC}
                      className="border p-2 ms-doc-thumb"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePreviewPhoto(url)}
                    >
                      <img src={url} alt="doc" style={{ width: '100px' }} />
                    </div>
                  );
                })}
              </div>
            )}

            <button
              className="btn btn-secondary mt-3 ms-button"
              onClick={handleCloseView}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}

      {/* Editar */}
      {editingUser && !resetPassUser && !viewingUser && (
        <div className="card mb-4 ms-card ms-vision-line">
          <div className="card-body">
            <h5 className="card-title ms-card-title">
              Editar Usuario (ID={editingUser.ID_USUARIO})
            </h5>

            {/* Nombres */}
            <div className="mb-3 ms-field-group">
              <label className="form-label fw-bold">Nombres:</label>
              <input
                type="text"
                className="form-control ms-textfield"
                value={editingUser.nombres || ''}
                onChange={(e) =>
                  setEditingUser(prev => ({ ...prev, nombres: e.target.value }))
                }
              />
            </div>

            {/* Apellidos */}
            <div className="mb-3 ms-field-group">
              <label className="form-label fw-bold">Apellidos:</label>
              <input
                type="text"
                className="form-control ms-textfield"
                value={editingUser.apellidos || ''}
                onChange={(e) =>
                  setEditingUser(prev => ({ ...prev, apellidos: e.target.value }))
                }
              />
            </div>

            {/* Celular */}
            <div className="mb-3 ms-field-group">
              <label className="form-label fw-bold">Celular:</label>
              <input
                type="text"
                className="form-control ms-textfield"
                value={editingUser.celular || ''}
                onChange={(e) =>
                  setEditingUser(prev => ({ ...prev, celular: e.target.value }))
                }
              />
            </div>

            {/* Rol */}
            <div className="mb-3 ms-field-group">
              <label className="form-label fw-bold">Rol:</label>
              <select
                className="form-select ms-select"
                value={editingUser.idRol || ''}
                onChange={(e) =>
                  setEditingUser(prev => ({ ...prev, idRol: e.target.value }))
                }
              >
                <option value="">-- Seleccionar --</option>
                {roles.map(r => (
                  <option key={r.id} value={r.id}>
                    {r.nombre}
                  </option>
                ))}
              </select>
            </div>

            {/* Estado */}
            <div className="mb-3 ms-field-group">
              <label className="form-label fw-bold">Estado:</label>
              <select
                className="form-select ms-select"
                value={editingUser.estado || ''}
                onChange={(e) =>
                  setEditingUser(prev => ({ ...prev, estado: e.target.value }))
                }
              >
                <option value="">-- Seleccionar --</option>
                <option value="PENDIENTE">PENDIENTE</option>
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </select>
            </div>

            <div className="d-flex gap-2">
              <button className="btn btn-success ms-button" onClick={handleSave}>
                Guardar Cambios
              </button>
              <button className="btn btn-secondary ms-button" onClick={handleCancel}>
                Cancelar
              </button>
            </div>

            <hr className="my-4" />
            <h5>Documentos de Identidad:</h5>
            {userDocs.length === 0 ? (
              <p>No hay documentos</p>
            ) : (
              <div className="d-flex flex-wrap gap-2">
                {userDocs.map((doc) => {
                  const url = varbinaryToBlobURL(doc.FOTO_DOC);
                  if (!url) return null;
                  return (
                    <div
                      key={doc.ID_DOC}
                      className="border p-2 ms-doc-thumb"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePreviewPhoto(url)}
                    >
                      <img src={url} alt="doc" style={{ width: '100px' }} />
                    </div>
                  );
                })}
              </div>
            )}

            {userDocs.length > 0 && (
              <button className="btn btn-outline-danger my-2 ms-button" onClick={handleDeleteDocs}>
                Eliminar todos los documentos
              </button>
            )}

            <div className="mt-3 ms-field-group">
              <p className="fw-bold mb-1">Subir/Reemplazar Documentos:</p>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="form-control ms-textfield"
              />
              <button
                className="btn btn-outline-primary mt-2 ms-button"
                onClick={handleUploadDocs}
              >
                Subir
              </button>
            </div>
          </div>
        </div>
      )}

      {/* CAMBIAR CONTRASEÑA */}
      {resetPassUser && (
        <div className="card mb-4 ms-card ms-vision-line">
          <div className="card-body">
            <h4 className="card-title ms-card-title">
              Cambiar Contraseña de {resetPassUser.NOMBRES_USUARIO} (ID={resetPassUser.ID_USUARIO})
            </h4>
            <div className="mb-3 ms-field-group">
              <label className="form-label fw-bold">Nueva Contraseña:</label>
              <input
                type="password"
                className="form-control ms-textfield"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="d-flex gap-2">
              <button className="btn btn-primary ms-button" onClick={handleSavePassword}>
                Guardar Contraseña
              </button>
              <button className="btn btn-secondary ms-button" onClick={handleCancelPassword}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* LISTADO DE USUARIOS */}
      {!editingUser && !resetPassUser && !viewingUser && (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 mt-3 ms-list-container">
          {users.map((u) => {
            // Buscar nombre del rol en "roles"
            const roleObj = roles.find(r => r.id === u.ID_ROL);
            const roleName = roleObj ? roleObj.nombre : 'Desconocido';

            return (
              <div className="col" key={u.ID_USUARIO}>
                <div className="card h-100 shadow d-flex flex-column ms-usercard ms-vision-line">
                  <div className="card-body flex-grow-1 ms-usercard-body">
                    <h5 className="card-title ms-card-title">
                      {u.NOMBRES_USUARIO} {u.APELLIDOS_USUARIO}
                    </h5>
                    <p className="card-text mb-1 ms-field">
                      <strong>Cédula:</strong> {u.CEDULA_USUARIO}
                    </p>
                    <p className="card-text mb-1 ms-field">
                      <strong>Rol:</strong> {roleName}
                    </p>
                    {u.ESTADO_USUARIO && (
                      <p className="card-text mb-1 ms-field">
                        <strong>Estado:</strong> {u.ESTADO_USUARIO}
                      </p>
                    )}
                  </div>
                  <div className="card-footer bg-transparent d-flex gap-2 flex-wrap ms-usercard-footer">
                    {/* Botón ver detalle */}
                    <button
                      className="btn btn-info btn-sm ms-button"
                      onClick={() => handleViewClick(u)}
                    >
                      Ver Detalle
                    </button>

                    {/* Botón editar */}
                    <button
                      className="btn btn-primary btn-sm ms-button"
                      onClick={() => handleEditClick(u)}
                    >
                      Editar
                    </button>

                    {/* Botón cambiar contraseña */}
                    <button
                      className="btn btn-warning btn-sm ms-button"
                      onClick={() => handleChangePasswordClick(u)}
                    >
                      Password
                    </button>

                    {/* Botones aprobar/rechazar si es PENDIENTE */}
                    {u.ESTADO_USUARIO === 'PENDIENTE' && (
                      <>
                        <button
                          className="btn btn-success btn-sm ms-button"
                          onClick={() => handleApprove(u)}
                        >
                          Aprobar
                        </button>
                        <button
                          className="btn btn-danger btn-sm ms-button"
                          onClick={() => handleReject(u.ID_USUARIO)}
                        >
                          Rechazar
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* MODAL para foto fullscreen */}
      <div
        className="modal fade"
        id="photoModal"
        tabIndex="-1"
        aria-labelledby="photoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-fullscreen ms-modal-dialog">
          <div className="modal-content ms-modal-content">
            <div className="modal-header ms-modal-header">
              <h5 className="modal-title ms-modal-title" id="photoModalLabel">Vista de la foto</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex justify-content-center align-items-center ms-modal-body">
              {modalImage && (
                <img
                  src={modalImage}
                  alt="FotoDoc"
                  style={{ maxWidth: '100%', maxHeight: '90vh' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
