// src/pages/DashboardSeguridad.jsx
import { Link } from 'react-router-dom';

const DashboardSeguridad = () => {
  return (
    <div>
      <h2>Panel de Seguridad</h2>
      <nav>
        <ul>
          <li><Link to="/salidas">Ver Salidas Aprobadas por Admin</Link></li>
          <li><Link to="/salidas/search">Buscar Salida por Código/Cédula</Link></li>
          {/* Para aprobar: /salidas/approve/security/:id */}
          {/* Nuevo: Mis Salidas */}
          <li><Link to="/mis-salidas">Mis Salidas/colaboraciones</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default DashboardSeguridad;
