// src/components/ProtectedRoute.jsx
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children, rolesAllowed }) => {
  const { user } = useAuth();

  if (!user) {
    // Si no está logueado, redirige a /login
    return <Navigate to="/login" replace />;
  }

  // Si se especifican rolesPermitidos, verificamos
  if (rolesAllowed && !rolesAllowed.includes(user.rol)) {
    // No tiene el rol requerido
    return <h1>No tienes permiso para acceder a esta sección</h1>;
  }

  return children;
};

export default ProtectedRoute;
