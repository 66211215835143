import { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import './UserManagement.css'; // para estilos adicionales

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [resetPassUser, setResetPassUser] = useState(null); // Para cambiar pass
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');

  // Para los documentos de identidad
  const [userDocs, setUserDocs] = useState([]); // docs actuales (varbinary)
  const [newDocs, setNewDocs] = useState([]);   // archivos a subir

  // Contraseña nueva
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    fetchUsers();
    setRoles([
      { id: 1, nombre: 'administrador' },
      { id: 2, nombre: 'seguridad' },
      { id: 3, nombre: 'encargado' }
    ]);
  }, []);

  // ========== CARGAR USUARIOS ==========
  const fetchUsers = async () => {
    try {
      const { data } = await axiosInstance.get('/users/all');
      setUsers(data);
    } catch (err) {
      console.error(err);
      setError('Error al cargar usuarios');
    }
  };

  // ========== ENTRAR EN MODO EDICIÓN DE UN USUARIO ==========
  const handleEditClick = async (u) => {
    setMsg('');
    setError('');
    setResetPassUser(null);

    // Mapeamos datos a "editingUser" para form
    setEditingUser({
      ...u,
      nombres: u.NOMBRES_USUARIO,
      apellidos: u.APELLIDOS_USUARIO,
      celular: u.CELULAR_USUARIO,
      idRol: u.ID_ROL
    });

    // Cargar docs
    try {
      const { data } = await axiosInstance.get(`/users/${u.ID_USUARIO}/docs`);
      setUserDocs(data.docs || []);
      setNewDocs([]);
    } catch (err) {
      console.error(err);
      setError('Error al cargar documentos del usuario');
    }
  };

  // ========== GUARDAR DATOS DE USUARIO ==========
  const handleSave = async () => {
    setMsg('');
    setError('');
    try {
      // PUT /users/:id
      await axiosInstance.put(`/users/${editingUser.ID_USUARIO}`, {
        nombres: editingUser.nombres,
        apellidos: editingUser.apellidos,
        celular: editingUser.celular,
        idRol: editingUser.idRol ? Number(editingUser.idRol) : null
      });
      setMsg('Usuario actualizado correctamente');
      fetchUsers();
      // Opcional: setEditingUser(null);
    } catch (err) {
      console.error(err);
      setError('Error al actualizar usuario');
    }
  };

  const handleCancel = () => {
    setEditingUser(null);
    setUserDocs([]);
    setNewDocs([]);
  };

  // ========== DOCS: ELIMINAR TODOS ==========
  const handleDeleteDocs = async () => {
    if (!editingUser) return;
    try {
      await axiosInstance.delete(`/users/${editingUser.ID_USUARIO}/docs`);
      setMsg('Documentos eliminados');
      setUserDocs([]);
      setNewDocs([]);
    } catch (err) {
      console.error(err);
      setError('Error al eliminar documentos');
    }
  };

  // ========== DOCS: SUBIR NUEVOS ==========
  const handleFileChange = (e) => {
    setNewDocs(Array.from(e.target.files));
  };

  const handleUploadDocs = async () => {
    if (!editingUser) return;
    if (newDocs.length === 0) {
      setError('No has seleccionado archivos');
      return;
    }
    setMsg('');
    setError('');
    try {
      const formData = new FormData();
      newDocs.forEach((file) => {
        formData.append('docs', file);
      });
      await axiosInstance.put(`/users/${editingUser.ID_USUARIO}/docs`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setMsg('Nuevos documentos subidos');
      // Recargar docs
      const { data } = await axiosInstance.get(`/users/${editingUser.ID_USUARIO}/docs`);
      setUserDocs(data.docs || []);
      setNewDocs([]);
    } catch (err) {
      console.error(err);
      setError('Error al subir documentos');
    }
  };

  // ========== CAMBIO DE CAMPOS EN MODO EDICION ==========
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingUser((prev) => ({ ...prev, [name]: value }));
  };

  // ========== CONVERTIR VARBINARY -> BLOB ==========
  const varbinaryToBlobURL = (vbArray) => {
    if (!vbArray) return null;
    const arr = vbArray.data || vbArray;
    if (!arr) return null;
    const u8 = new Uint8Array(arr);
    const blob = new Blob([u8], { type: 'image/jpeg' });
    return URL.createObjectURL(blob);
  };

  // ========== CAMBIAR CONTRASEÑA ==========
  const handleChangePasswordClick = (u) => {
    setResetPassUser(u);
    setNewPassword('');
    setMsg('');
    setError('');
    setEditingUser(null);
    setUserDocs([]);
    setNewDocs([]);
  };

  const handleSavePassword = async () => {
    if (!newPassword) {
      setError('Por favor ingresa la nueva contraseña');
      return;
    }
    try {
      await axiosInstance.put(`/users/${resetPassUser.ID_USUARIO}/password`, {
        newPassword
      });
      setMsg(`Contraseña de ${resetPassUser.NOMBRES_USUARIO} actualizada`);
      setResetPassUser(null);
      setNewPassword('');
    } catch (err) {
      console.error(err);
      setError('Error al cambiar la contraseña');
    }
  };

  const handleCancelPassword = () => {
    setResetPassUser(null);
  };

  return (
    <div className="container my-4">
      <h2 className="mb-3 text-center">Gestión de Usuarios</h2>

      {/* ERRORES Y MENSAJES */}
      {error && <div className="alert alert-danger">{error}</div>}
      {msg && <div className="alert alert-success">{msg}</div>}

      {/* FORM DE EDICIÓN DE DATOS Y DOCUMENTOS */}
      {editingUser && !resetPassUser && (
        <div className="card mb-4">
          <div className="card-body">
            
            
            {/* Campos básicos */}
            <div className="mb-3">
              <label className="form-label fw-bold">Nombres:</label>
              <input
                type="text"
                name="nombres"
                className="form-control"
                value={editingUser.nombres || ''}
                onChange={handleChange}
              />
            </div>
            
            <div className="mb-3">
              <label className="form-label fw-bold">Apellidos:</label>
              <input
                type="text"
                name="apellidos"
                className="form-control"
                value={editingUser.apellidos || ''}
                onChange={handleChange}
              />
            </div>
            
            <div className="mb-3">
              <label className="form-label fw-bold">Celular:</label>
              <input
                type="text"
                name="celular"
                className="form-control"
                value={editingUser.celular || ''}
                onChange={handleChange}
              />
            </div>
            
            <div className="mb-3">
              <label className="form-label fw-bold">Rol:</label>
              <select
                name="idRol"
                className="form-select"
                value={editingUser.idRol || ''}
                onChange={handleChange}
              >
                <option value="">-- Seleccionar --</option>
                {roles.map((r) => (
                  <option key={r.id} value={r.id}>
                    {r.nombre}
                  </option>
                ))}
              </select>
            </div>
            
            <div className="d-flex gap-2">
              <button className="btn btn-success" onClick={handleSave}>
                Guardar Cambios
              </button>
              <button className="btn btn-secondary" onClick={handleCancel}>
                Cancelar
              </button>
            </div>

            <hr className="my-4" />

            <h5>Documentos de Identidad:</h5>
            {userDocs.length === 0 ? (
              <p>No hay documentos</p>
            ) : (
              <div className="d-flex flex-wrap gap-3">
                {userDocs.map((doc) => {
                  const url = varbinaryToBlobURL(doc.FOTO_DOC);
                  if (!url) return null;
                  return (
                    <div key={doc.ID_DOC} className="border p-2">
                      <img src={url} alt="doc" style={{ width:'100px' }} />
                    </div>
                  );
                })}
              </div>
            )}

            {userDocs.length > 0 && (
              <button className="btn btn-outline-danger my-2" onClick={handleDeleteDocs}>
                Eliminar todos los documentos
              </button>
            )}

            <div className="mt-3">
              <p className="fw-bold mb-1">Subir/Reemplazar Documentos:</p>
              <input type="file" multiple onChange={handleFileChange} className="form-control"/>
              <button className="btn btn-outline-primary mt-2" onClick={handleUploadDocs}>
                Subir
              </button>
            </div>
          </div>
        </div>
      )}

      {/* FORM PARA CAMBIO DE CONTRASEÑA */}
      {resetPassUser && (
        <div className="card mb-4">
          <div className="card-body">
            <h4 className="card-title">
              Cambiar Contraseña de {resetPassUser.NOMBRES_USUARIO} (ID={resetPassUser.ID_USUARIO})
            </h4>
            <div className="mb-3">
              <label className="form-label fw-bold">Nueva Contraseña:</label>
              <input
                type="password"
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="d-flex gap-2">
              <button className="btn btn-primary" onClick={handleSavePassword}>
                Guardar Contraseña
              </button>
              <button className="btn btn-secondary" onClick={handleCancelPassword}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* MOSTRAR USUARIOS EN CARDS SI NO SE ESTÁ EDITANDO NI CAMBIANDO PASS */}
      {!editingUser && !resetPassUser && (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 mt-3">
          {users.map((u) => (
            <div className="col" key={u.ID_USUARIO}>
              <div className="card h-100 shadow">
                <div className="card-body d-flex flex-column justify-content-between">
                  <div>
                    <h5 className="card-title">
                      {u.NOMBRES_USUARIO} {u.APELLIDOS_USUARIO}
                    </h5>
                    <p className="card-text mb-1">
                      <strong>ID:</strong> {u.ID_USUARIO}
                    </p>
                    <p className="card-text mb-1">
                      <strong>Cédula:</strong> {u.CEDULA_USUARIO}
                    </p>
                    <p className="card-text mb-1">
                      <strong>Rol:</strong> {u.ID_ROL}
                    </p>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn btn-primary btn-sm me-2"
                      onClick={() => handleEditClick(u)}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-warning btn-sm"
                      onClick={() => handleChangePasswordClick(u)}
                    >
                      Cambiar Contraseña
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserManagement;
