// src/pages/Register.jsx
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Register = () => {
  const navigate = useNavigate();
  const { registerUser } = useAuth();

  // Campos de texto
  const [cedula, setCedula] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [celular, setCelular] = useState('');
  const [password, setPassword] = useState('');

  // Archivos de docs
  const [docs, setDocs] = useState([]);

  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');

  const handleFileChange = (e) => {
    setDocs(Array.from(e.target.files)); // array de File
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMsg('');

    if (!cedula || !nombres || !apellidos || !password) {
      setError('Por favor llena todos los campos obligatorios');
      return;
    }

    try {
      await registerUser({ cedula, nombres, apellidos, celular, password }, docs);
      setMsg('Usuario registrado con éxito (rol=encargado)');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      console.error(err);
      setError('Error al registrar usuario: ' + (err.response?.data?.message || ''));
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto' }}>
      <h2>Registro de Usuario</h2>
      <form onSubmit={handleSubmit}>

        <div>
          <label>Cédula:</label>
          <input 
            type="text"
            value={cedula}
            onChange={(e) => setCedula(e.target.value)}
          />
        </div>
        <div>
          <label>Nombres:</label>
          <input 
            type="text"
            value={nombres}
            onChange={(e) => setNombres(e.target.value)}
          />
        </div>
        <div>
          <label>Apellidos:</label>
          <input 
            type="text"
            value={apellidos}
            onChange={(e) => setApellidos(e.target.value)}
          />
        </div>
        <div>
          <label>Celular:</label>
          <input 
            type="text"
            value={celular}
            onChange={(e) => setCelular(e.target.value)}
          />
        </div>
        <div>
          <label>Contraseña:</label>
          <input 
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div>
          <label>Documento(s) de Identidad:</label>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <small>Puedes subir varias fotos (frontal, reverso, etc.)</small>
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>}
        {msg && <p style={{ color: 'green' }}>{msg}</p>}

        <button type="submit">Registrar</button>
      </form>

      <p>
        ¿Ya tienes cuenta? <Link to="/login">Inicia sesión</Link>
      </p>
    </div>
  );
};

export default Register;
