// axiosInstance.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'https://www.keybillindigital.com';

const axiosInstance = axios.create({
  baseURL: `${baseURL}/api`,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
