import { Link } from 'react-router-dom';

function DashboardAdmin() {
  return (
    <div className="container mt-4">
      {/* Título central */}
      <h2 className="text-center mb-4">Panel de Administrador</h2>

      {/* 
        Row responsiva:
        row-cols-1 => 1 columna en pantallas muy pequeñas
        row-cols-sm-2 => 2 columnas en pantallas pequeñas
        row-cols-md-3 => 3 columnas en pantallas medianas
        g-4 => gap de 1.5rem entre cards
      */}
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">

        {/* Card 1: Ver todas las Salidas */}
        <div className="col">
          <div className="card h-100 text-center shadow">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              {/* Ícono con clase Bootstrap Icons */}
              <i className="bi bi-list-ul fs-1 mb-3 text-primary"></i>
              <h5 className="card-title">Ver todas las Salidas</h5>
            </div>
            {/* Link que hace toda la card clickeable */}
            <Link to="/salidas" className="stretched-link"></Link>
          </div>
        </div>

        {/* Card 2: Crear Nueva Salida */}
        <div className="col">
          <div className="card h-100 text-center shadow">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-plus-square fs-1 mb-3 text-success"></i>
              <h5 className="card-title">Crear Nueva Salida</h5>
            </div>
            <Link to="/salidas/crear" className="stretched-link"></Link>
          </div>
        </div>

        {/* Card 3: Aprobar Salidas Pendientes */}
        <div className="col">
          <div className="card h-100 text-center shadow">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-check2-circle fs-1 mb-3 text-info"></i>
              <h5 className="card-title">Aprobar Salidas Pendientes</h5>
            </div>
            <Link to="/admin/aprobar" className="stretched-link"></Link>
          </div>
        </div>

        {/* Card 4: Gestión de Usuarios */}
        <div className="col">
          <div className="card h-100 text-center shadow">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-people fs-1 mb-3 text-warning"></i>
              <h5 className="card-title">Gestión de Usuarios</h5>
            </div>
            <Link to="/admin/users" className="stretched-link"></Link>
          </div>
        </div>

        {/* Card 5: Mis Salidas/colaboraciones */}
        <div className="col">
          <div className="card h-100 text-center shadow">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-clock-history fs-1 mb-3 text-secondary"></i>
              <h5 className="card-title">Mis Salidas/colaboraciones</h5>
            </div>
            <Link to="/mis-salidas" className="stretched-link"></Link>
          </div>
        </div>

      </div>
    </div>
  );
}

export default DashboardAdmin;
