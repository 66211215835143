// src/pages/SalidaSearch.jsx
import { useState } from 'react';
import axiosInstance from '../api/axiosInstance';

const SalidaSearch = () => {
  const [codigo, setCodigo] = useState('');
  const [cedula, setCedula] = useState('');
  const [results, setResults] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setResults([]);
    try {
      let url = '/salidas/search?';
      if (codigo) {
        url += `codigo=${codigo}`;
      } else if (cedula) {
        url += `cedula=${cedula}`;
      } else {
        alert('Ingresa un código o una cédula');
        return;
      }
      const { data } = await axiosInstance.get(url);
      setResults(data.salidas || []);
    } catch (error) {
      console.error(error);
      alert('Error en la búsqueda');
    }
  };

  return (
    <div>
      <h2>Buscar Salida</h2>
      <form onSubmit={handleSearch}>
        <div>
          <label>Código de Salida:</label>
          <input
            type="text"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            disabled={cedula.length > 0}
          />
        </div>
        <div>
          <label>Cédula:</label>
          <input
            type="text"
            value={cedula}
            onChange={(e) => setCedula(e.target.value)}
            disabled={codigo.length > 0}
          />
        </div>
        <button type="submit">Buscar</button>
      </form>

      <h3>Resultados</h3>
      {results.length === 0 && <p>No hay resultados</p>}
      <ul>
        {results.map((r) => (
          <li key={r.ID_SALIDA}>
            Salida #{r.ID_SALIDA} - Motivo: {r.MOTIVO_SALIDA} - Estado: {r.ESTADO_SALIDA}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SalidaSearch;
